import { DateFormat, Participant } from '@range.io/basic-types'
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-dev.js'
import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useSelector, useStore } from 'react-redux'
import { Avatar } from '../components-reusable/Avatar.js'
import { FlexColumn, FlexRow } from '../components-reusable/Flex.js'
import { URL_SEARCH_ORDER, useImageUrl } from '../components-reusable/hooks/useImageUrl.js'
import Icon from '../components-reusable/Icon.js'
import Image from '../components-reusable/Image.js'
import { styled } from '../range-theme/index.js'
import { ReduxSelectors } from '../redux/index.js'
import Identifier from './Identifier.js'

// ---------------------------------------------------------------------------------------------------------------------
// Styles
// ---------------------------------------------------------------------------------------------------------------------

const PopupWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    mb: 18,
})

const PopupContent = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    padding: '12px 8px 8px',
    gap: '4px',
    width: '200px',
    background: '#292B3D',
    borderRadius: '16px',
    flex: 'none',
    order: '0',
    flexGrow: '0',
})

const PopupContentTask = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    boxSizing: 'border-box',
    padding: '16px 16px 16px 16px',
    gap: '4px',
    width: '250px',
    background: '#292B3D',
    borderRadius: '16px',
    flex: 'none',
    order: '0',
    flexGrow: '0',
})

const PopupTip = styled('div', {
    width: '10px',
    height: '10px',
    background: '#292B3D',
    transform: 'translateY(-50%) rotate(45deg)',
    flex: 'none',
    order: '1',
    flexGrow: '0',
})

const ImageWrapper = styled('div', {
    width: '100%',
    height: '140px',
    background: '#424259',
    display: 'flex',
    borderRadius: '13px',
    zIndex: '999',
    overflow: 'hidden',
})

const ImageCaption = styled('div', {
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '18px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#ffffff',
    opacity: '0.6',
    padding: '0 4px',
})

// ---------------------------------------------------------------------------------------------------------------------
// Components
// ---------------------------------------------------------------------------------------------------------------------

/*
 * PhotoMarkerPopup - React component for contents of the popup for photo markers when they are hovered over.
 * Has loading animation before the actual image is downloaded and ready to display.
 */
const PhotoMarkerPopup = ({ upload, photoCount, name, projectIdentifier, collaborationIdentifier }) => {
    const { url } = useImageUrl(URL_SEARCH_ORDER.ANNOTATED_THUMBNAIL_1, upload?.id)

    const getPhotoCountText = () => {
        if (photoCount === 0) return '0 photos added'
        if (photoCount === 2) return '+1 more photo'
        return `+${photoCount - 1} more photos`
    }

    const isNameEmpty = name.length === 0

    return (
        <PopupWrapper>
            <PopupContent>
                <FlexColumn css={{ padding: '0 6px', marginBottom: '8px' }}>
                    {collaborationIdentifier && (
                        <FlexRow css={{ width: '100%', mb: 4 }} alignItems="flex-start">
                            <Identifier
                                projectIdentifier={projectIdentifier}
                                collaborationIdentifier={collaborationIdentifier}
                                variant="small"
                            />
                        </FlexRow>
                    )}
                    <StyledTaskPrimaryTextPhotoPin>
                        {isNameEmpty ? 'Untitled photo pin…' : name}
                    </StyledTaskPrimaryTextPhotoPin>
                </FlexColumn>

                {photoCount > 0 && (
                    <ImageWrapper>
                        <Image src={url} />
                    </ImageWrapper>
                )}
                {photoCount !== 1 && <ImageCaption>{getPhotoCountText()}</ImageCaption>}
            </PopupContent>
            <PopupTip />
        </PopupWrapper>
    )
}

const StyledTaskPrimaryText = styled('div', {
    color: '#dddff0',
    fw: '500',
    fs: '16px',
    lineHeight: '20px',
    wordBreak: 'break-word',
})

const StyledTaskPrimaryTextPhotoPin = styled('div', {
    color: '#dddff0',
    fw: '500',
    fs: '13px',
    lineHeight: '17px',
    width: '100%',
    boxSizing: 'border-box',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
})

const StyledTaskSecondaryText = styled('div', {
    color: '#8d8ea4',
    fw: '400',
    fs: '12px',
    lineHeight: '18px',
})

const StyledDivider = styled('div', { width: '100%', border: '0.5px solid #FFFFFF15', margin: '8px 0' })

const StyledEmptyAvatar = styled(FlexRow, {
    br: 1000,
    bg: '#292B3D',
    border: '1px solid #FFFFFF15',
    width: '32px',
    height: '32px',
    ai: 'center',
    justifyContent: 'center',
    color: '#8d8ea4',
})

const StyledStatusPill = styled(FlexRow, {
    color: '#FFFFFF',
    fw: '500',
    fs: '13px',
    lineHeight: '16px',
    br: '100px',
    padding: '3px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
})

const TaskMarkerPopup = ({
    archivedDate,
    assignee,
    dueDate,
    name,
    statusName,
    projectIdentifier,
    collaborationIdentifier,
}) => {
    const assigneeText = assignee ? Participant.fullName(assignee) : 'No assignee'
    const isOverdue = dueDate && dueDate.getTime() < Date.now()
    const dueDateText = dueDate ? DateFormat.primary(dueDate) : 'No due date'
    const isArchived = !!archivedDate
    const showOverdueStatus = isOverdue && !isArchived && !statusName.isCompleted
    const statusPillColor = isArchived ? '$archived' : statusName.color
    const statusPillText = isArchived ? 'Archived' : statusName.name
    const calendarIconCss = {
        br: 1000,
        bg: '#292B3D',
        color: showOverdueStatus ? '$red03' : '#8d8ea4',
        border: showOverdueStatus ? '1px solid $red03' : '1px solid #FFFFFF15',
        p: 8,
        boxSizing: 'content-box',
    }

    const isNameEmpty = name.length === 0

    return (
        <PopupWrapper>
            <PopupContentTask>
                <FlexColumn css={{ gap: '8px', width: '100%' }}>
                    <FlexRow css={{ ai: 'center', gap: 8, mb: 4 }}>
                        <StyledStatusPill css={{ bg: statusPillColor }}>{statusPillText}</StyledStatusPill>
                        {isArchived && (
                            <StyledTaskSecondaryText>{DateFormat.primary(archivedDate)}</StyledTaskSecondaryText>
                        )}
                    </FlexRow>
                    {collaborationIdentifier && (
                        <Identifier
                            projectIdentifier={projectIdentifier}
                            collaborationIdentifier={collaborationIdentifier}
                            variant="small"
                        />
                    )}
                    <StyledTaskPrimaryText>{isNameEmpty ? 'Untitled task…' : name}</StyledTaskPrimaryText>
                </FlexColumn>
                <StyledDivider />
                <FlexRow css={{ gap: '8px', alignItems: 'center' }}>
                    {assignee ? (
                        <Avatar
                            css={{ bg: '#292B3D', border: '1px solid #FFFFFF15', color: '#8d8ea4' }}
                            size="32"
                            url={assignee?.avatarUrl}
                            fallbackText={Participant.initialLetter(assignee)}
                        />
                    ) : (
                        <StyledEmptyAvatar>
                            <Icon name="user" iconSize="16" />
                        </StyledEmptyAvatar>
                    )}
                    <FlexColumn>
                        <StyledTaskSecondaryText>Assigned to</StyledTaskSecondaryText>
                        <StyledTaskPrimaryText
                            css={{
                                textDecoration: assignee?.isSuspended ? 'line-through' : 'none',
                            }}
                        >
                            {assigneeText}
                        </StyledTaskPrimaryText>
                    </FlexColumn>
                </FlexRow>
                <StyledDivider />
                <FlexRow css={{ gap: '8px', alignItems: 'center' }}>
                    <Icon name="calendar" iconSize="16px" css={calendarIconCss} />
                    <FlexColumn>
                        <StyledTaskSecondaryText>Due Date</StyledTaskSecondaryText>
                        <StyledTaskPrimaryText css={{ color: showOverdueStatus ? '$red03' : '#dddff0' }}>
                            {dueDateText}
                        </StyledTaskPrimaryText>
                    </FlexColumn>
                </FlexRow>
            </PopupContentTask>
            <PopupTip />
        </PopupWrapper>
    )
}

/*
 * MapPopupController - responsible for managing popups (small modals appearing on mouse over actions)
 * on map elements  (currently only on photo markers). Use Mapbox Popup API as a based and with React.createPortal
 * renders into it a customized component.
 */
const MapPopupController = ({ mapboxMap }) => {
    const [popupProps, setPopupProps] = useState()
    const { getState } = useStore()
    // sole purpose of next line is to make sure this component gets re-rendered when any upload changes, which might mean we got the thumbnailURL we need for shown upload - don't remove, it works, really.
    useSelector(ReduxSelectors.uploadLookupTable)

    const popup = useRef(
        new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
            maxWidth: 'none', // to ensure popup resizes to fit the content
            offset: [0, -8],
        })
    )

    /*
     * Requests change of the mouse cursor to the selected state
     */
    const setSelectedCursor = () => mapboxMap.fire('draw.changeCursor', { cursorName: 'hover-selected' })

    /*
     * Requests reset of the mouse cursor to default. Does it only if there's no passed mouse event or if there's an event, then no mouse button is pressed
     */
    const resetCursor = e => (!e || e?.originalEvent.buttons === 0) && mapboxMap.fire('draw.resetCursor')

    const hidePopup = () => {
        if (popup.current.isOpen()) {
            // removing popup from the map was causing issues, so now we change its' position to North Pole
            popup.current.setLngLat([0, 90])
            resetCursor()
            addBufferedLayerListeners()
            setPopupProps(null)
        }
    }

    /*
     * Based on a given mouse event show map popup for photo marker it's hovering over
     */
    const handleMouseOverPinMarker = e => {
        const collaboration = ReduxSelectors.firstCollaborationForGeometry(getState(), e.features[0].properties.id)

        const { name, dueDate, assignee: assigneeId, identifier: collaborationIdentifier } = collaboration
        const statusName = ReduxSelectors.statusNameForCollaboration(getState(), collaboration)
        const projectIdentifier = ReduxSelectors.selectedProject(getState())?.identifier

        // this means it's a task marker
        if (statusName) {
            const assignee = assigneeId ? ReduxSelectors.selectedProjectParticipantWithId(getState(), assigneeId) : null
            const geometry = ReduxSelectors.geometryForCollaboration(getState(), collaboration)
            const archivedDate = geometry?.archivedDate
            setPopupProps({
                archivedDate,
                statusName,
                name,
                assignee,
                dueDate,
                projectIdentifier,
                collaborationIdentifier,
            })
        } else {
            // and this means it's a photo marker
            const uploads = ReduxSelectors.uploadsForCollaboration(getState(), collaboration)
            const hasNote = ReduxSelectors.commentsForCollaboration(getState(), collaboration).length > 0
            const mainUpload = uploads.sort((a, b) => b.createdAt - a.createdAt)[0]
            setPopupProps({
                upload: mainUpload,
                photoCount: uploads.length,
                hasNote,
                name,
                projectIdentifier,
                collaborationIdentifier,
            })
        }
        const coordinates = e.features[0].geometry.coordinates.slice()
        popup.current.setLngLat(coordinates)

        mapboxMap.fire('draw.changeCursor', { cursorName: 'hover-selected' })
    }

    const handleMouseOverColdLayer = e => {
        mapboxMap.off('mouseenter', 'gl-draw-inactive-pin-marker.hot', handleMouseOverHotLayer)
        handleMouseOverPinMarker(e)
    }
    const handleMouseOverHotLayer = e => {
        mapboxMap.off('mouseenter', 'gl-draw-inactive-pin-marker.cold', handleMouseOverColdLayer)
        handleMouseOverPinMarker(e)
    }

    const addBufferedLayerListeners = () => {
        // remove event listeners for safety, so they won't be added many times
        mapboxMap.off('mouseenter', 'gl-draw-inactive-pin-marker.cold', handleMouseOverColdLayer)
        mapboxMap.off('mouseenter', 'gl-draw-inactive-pin-marker.hot', handleMouseOverHotLayer)
        // add required event listeners
        mapboxMap.on('mouseenter', 'gl-draw-inactive-pin-marker.cold', handleMouseOverColdLayer)
        mapboxMap.on('mouseenter', 'gl-draw-inactive-pin-marker.hot', handleMouseOverHotLayer)
    }

    useEffect(() => {
        if (!mapboxMap) return
        popup.current.addTo(mapboxMap).setHTML('')

        addBufferedLayerListeners()
        mapboxMap.on('mouseenter', 'gl-draw-active-pin-marker.cold', setSelectedCursor)
        mapboxMap.on('mouseenter', 'gl-draw-active-pin-marker.hot', setSelectedCursor)
        mapboxMap.on('mouseleave', 'gl-draw-active-pin-marker.cold', resetCursor)
        mapboxMap.on('mouseleave', 'gl-draw-active-pin-marker.hot', resetCursor)
        mapboxMap.on('mouseleave', 'gl-draw-inactive-pin-marker.cold', hidePopup)
        mapboxMap.on('mouseleave', 'gl-draw-inactive-pin-marker.hot', hidePopup)
        mapboxMap.on('click', 'gl-draw-inactive-pin-marker.cold', hidePopup)
        mapboxMap.on('click', 'gl-draw-inactive-pin-marker.hot', hidePopup)
    }, [mapboxMap])

    const onMount = () => {
        return () => {
            if (!mapboxMap) return

            if (popup.current.isOpen()) popup.current.remove()

            mapboxMap.off('mouseenter', 'gl-draw-active-pin-marker.cold', setSelectedCursor)
            mapboxMap.off('mouseenter', 'gl-draw-active-pin-marker.hot', setSelectedCursor)
            mapboxMap.off('mouseleave', 'gl-draw-active-pin-marker.cold', resetCursor)
            mapboxMap.off('mouseleave', 'gl-draw-active-pin-marker.hot', resetCursor)
            mapboxMap.off('mouseleave', 'gl-draw-inactive-pin-marker.cold', hidePopup)
            mapboxMap.off('mouseleave', 'gl-draw-inactive-pin-marker.hot', hidePopup)
            mapboxMap.off('click', 'gl-draw-inactive-pin-marker.cold', hidePopup)
            mapboxMap.off('click', 'gl-draw-inactive-pin-marker.hot', hidePopup)

            mapboxMap.on('mouseenter', 'gl-draw-inactive-pin-marker.cold', handleMouseOverColdLayer)
            mapboxMap.on('mouseenter', 'gl-draw-inactive-pin-marker.hot', handleMouseOverHotLayer)
        }
    }

    useEffect(onMount, [])

    const popupNode = document.getElementsByClassName('mapboxgl-popup-content')[0]

    return popupNode && popupProps
        ? ReactDOM.createPortal(
              popupProps.statusName ? <TaskMarkerPopup {...popupProps} /> : <PhotoMarkerPopup {...popupProps} />,
              popupNode
          )
        : null
}

export default MapPopupController
