/*
 * Admin Page: Projects panel
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Box, Button, FlexColumn, FlexRow, Text } from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'
import { ReduxSelectors } from '../redux/index.js'
import AdminOrganizationsPanel from './AdminOrganizationsPanel.js'
import Link from './Link.js'
import { visitCustomerPortal } from '../firebase/commands/https-calls.js'

const StyledPrimaryModalLabel = styled(Text, {
    fs: '20px',
    fw: 700,
    textAlign: 'center',
})

const StyledOuterBox = styled(Box, {
    bg: '$neutral10',
    color: '$neutral04',
    width: '600px',
    border: '1px solid $neutral07',
    br: '6px',
    boxShadow: '0px 40px 40px #00000015',
    zIndex: 1000,
    position: 'relative',
})

const StyledSecondaryModalLabel = styled(Text, {
    fs: '14px',
    color: '$neutral05',
    lh: '1.4',
    textAlign: 'center',
})

const StyledOverlay = styled(FlexColumn, {
    width: '100%',
    height: '100%',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: '$neutralOverlay',
    ai: 'center',
    justifyContent: 'center',
    zIndex: 300,
})

const OrganizationIsDisabledModal = ({ title, description }) => (
    <StyledOverlay>
        <StyledOuterBox>
            <Box css={{ padding: '48px' }}>
                <FlexColumn css={{ alignItems: 'center', gap: '16px', paddingBottom: '16px' }}>
                    <img src="/secure-lock.png" alt="Lock" width="120" height="120" />
                    <StyledPrimaryModalLabel>{title}</StyledPrimaryModalLabel>
                </FlexColumn>
                <StyledSecondaryModalLabel>{description}</StyledSecondaryModalLabel>
            </Box>
        </StyledOuterBox>
    </StyledOverlay>
)

/*
 * The organization.isDisabled flag is set, so show just the organization selector column and a modal saying "call us"
 */
const AdminViewOrganizationIsDisabled = ({ type = 'freePlanLimit' }) => {
    const organizations = useSelector(ReduxSelectors.organizationsAsArray)
    const selectedOrganization = useSelector(ReduxSelectors.selectedOrganization)
    const isEditingOrganizationAllowed = useSelector(ReduxSelectors.isUpdateAllowed('organization'))

    const _visitCustomerPortal = async () => {
        const { url } = await visitCustomerPortal(selectedOrganization.id)
        window.location.href = url
    }

    let title = `Your ${selectedOrganization.name} Organization has been disabled for exceeding the limits of our free plan`
    let description =
        'We are unable to support free users indefinitely, please contact us at info@range.io so that we can migrate your Organization to a paid plan in order to continue using Range.'

    switch (type) {
        case 'billingError':
            title = isEditingOrganizationAllowed ? 'Your Range account has a billing issue' : 'Account Access Paused'
            description = (
                <FlexColumn style={{ gap: '16px', alignItems: 'center' }}>
                    <Text>
                        {isEditingOrganizationAllowed
                            ? `Your Range account has a billing issue, and the grace period has expired. To restore access to
                        your team’s data, please update the payment details for your organization.`
                            : `Your Range account has a billing issue, and the grace period has expired. To restore access to your team’s data, have a Range Admin update the payment details for your organization.`}
                    </Text>
                    <Text>
                        Questions? Contact us at <Link href="mailto:support@range.io">support@range.io</Link>
                    </Text>
                    {isEditingOrganizationAllowed && (
                        // TODO: navigate to the Stripe billing page
                        <Button variant="primary" size="lg" onClick={_visitCustomerPortal}>
                            Update Payment Details
                        </Button>
                    )}
                </FlexColumn>
            )
    }

    return (
        <FlexRow css={{ w: '100vw', h: '100vh', bg: '$neutral10' }}>
            <AdminOrganizationsPanel
                relativePath="projects"
                organizations={organizations}
                selectedOrganizationId={selectedOrganization?.id}
            />

            <OrganizationIsDisabledModal title={title} description={description} />
        </FlexRow>
    )
}

AdminViewOrganizationIsDisabled.propTypes = {
    type: PropTypes.oneOf(['freePlanLimit', 'billingError']),
}

export default AdminViewOrganizationIsDisabled
