/*
 * Update
 *
 * A Update is an element of the history of a Collaboration.
 */
import { Timestamp } from '@firebase/firestore'
import { mergeRight, tagged } from '@range.io/functional'
import { v4 } from 'uuid'
import { millisOrTimestampToDate } from '../helper/timestamp.js'
import StringTypes from '../string-types.js'

// ---------------------------------------------------------------------------------------------------------------------
// Definitions
// ---------------------------------------------------------------------------------------------------------------------

// prettier-ignore
const Update = tagged('Update', {
    id        : StringTypes.Id,
    parentId  : StringTypes.Id,
    parentType: /Collaboration/,
    createdAt : 'Object', // Date
    createdBy : StringTypes.Id,
    field     : 'String',
    oldValue  : 'Any?', // String|Int|Id
    newValue  : 'Any?', // String|Int|Id
    
    collaborationId: StringTypes.OptionalId,
})

// TODO: remove renames
Update.prototype.renameFieldButLoudly('parent', 'parentId')

// ---------------------------------------------------------------------------------------------------------------------
// Serialization
// ---------------------------------------------------------------------------------------------------------------------

// create a Date from a timestamp
Update.fromFirebase = o =>
    Update.from({
        id: o.id,
        parentId: o.parent,
        parentType: o.parentType,
        createdBy: o.createdBy,
        field: o.field,
        oldValue: o.oldValue && o.field === 'dueDateTimestamp' ? millisOrTimestampToDate(o.oldValue) : o.oldValue,
        newValue: o.newValue && o.field === 'dueDateTimestamp' ? millisOrTimestampToDate(o.newValue) : o.newValue,
        createdAt: millisOrTimestampToDate(o.createdAt),

        collaborationId: o.collaborationId,
    })

// create a timestamp from a Date
Update.toFirebase = u => ({
    id: u.id,
    parent: u.parentId,
    parentType: u.parentType,
    createdBy: u.createdBy,
    createdAt: Timestamp.fromDate(u.createdAt),
    field: u.field,
    oldValue: u.oldValue && u.field === 'dueDateTimestamp' ? Timestamp.fromDate(u.oldValue) : u.oldValue,
    newValue: u.newValue && u.field === 'dueDateTimestamp' ? Timestamp.fromDate(u.newValue) : u.newValue,
})

// ---------------------------------------------------------------------------------------------------------------------
// Functions on Updates
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Is the Update younger than seconds old?
 * @sig isYoungerThan :: (Update, Number) -> Boolean
 */
Update.isYoungerThan = (update, seconds) => update.createdAt.getTime() + seconds * 1000 >= Date.now()

// ---------------------------------------------------------------------------------------------------------------------
// Syntactic sugar for creating Updates
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Create a Update from an existing one, but include the passed-in changes
 * @sig update :: (Update, {k:v }) -> Update
 */
Update.update = (update, changes) => Update.from(mergeRight(update, changes))

/*
 * Add a CollaborationCreated event for the Collaboration
 * @sig createdEventForCollaboration :: ({ collaborationId: Id, userId: Id }) -> Update
 */
Update.collaborationChanged = ({ collaboration, userId, field, oldValue, newValue, id = v4(), date = new Date() }) =>
    Update.from({
        id,
        parentId: collaboration.id,
        parentType: 'Collaboration',
        date,
        userId,
        field: field === 'dueDate' ? 'dueDateTimestamp' : field,
        oldValue,
        newValue,
    })

export default Update
