/*
 * Organization
 *
 * A Organization is the top-level container for Range data
 */
import { filterObject, mergeRight, pick, renameKeys, tagged } from '@range.io/functional'
import { timestampToDate } from '../helper/timestamp.js'
import StringTypes from '../string-types.js'

// ---------------------------------------------------------------------------------------------------------------------
// Definitions
// ---------------------------------------------------------------------------------------------------------------------

// prettier-ignore
const Organization = tagged('Organization', {
    id                  : StringTypes.Id,
    name                : 'String',
    industry            : 'String',
    plan                : '/Free|Team|Enterprise/?', // required on read, but not allowed when creating an Organization
    logo                : StringTypes.OptionalImageUri,
    icon                : StringTypes.OptionalImageUri,
    projectIds          : StringTypes.Ids,
    participants        : 'Object?', // { [userId]: Owner|Collaborator|etc - optional because it's only set on initial load
    allowAi             : 'Boolean?',
    organizationSize    : StringTypes.OptionalOrganizationSize,
    limitsAndCounts     : 'Object?',
    
    seatCount           : 'Number?', // required on read, but not allowed when creating an Organization
    billingCycle        : '/^Monthly|Annual$/?',
    nextPaymentTimestamp: 'Object?', // Date
    billingError        : 'Object?',
    
    isDisabled          : 'Boolean?', // don't allow ANY action
})

// TODO: remove Organization renames
Organization.prototype.renameFieldButLoudly('projects', 'projectIds')

/*
 * Create a new Organization merging an old one with values from changes
 * @sig update :: (Organization, {k:v}) -> Organization
 */
Organization.update = (organization, changes) => Organization.from(mergeRight(organization, changes))

Organization.avatarFallbackText = organization => organization.name[0]

// prettier-ignore
Organization.industries= [
    { name: 'Agriculture / Forestry'            , id : 'Agriculture / Forestry'         } ,
    { name: 'Architecture'                      , id : 'Architecture'                   } ,
    { name: 'Civil Engineering'                 , id : 'Civil Engineering'              } ,
    { name: 'Construction'                      , id : 'Construction'                   } ,
    { name: 'Contractors - Specialty'           , id : 'Contractors - Specialty'        } ,
    { name: 'Drones'                            , id : 'Drones'                         } ,
    { name: 'Earth Observation - Satellites'    , id : 'Earth Observation - Satellites' } ,
    { name: 'Engineering'                       , id : 'Engineering'                    } ,
    { name: 'Energy / Utilities'                , id : 'Energy / Utilities'             } ,
    { name: 'GIS'                               , id : 'GIS'                            } ,
    { name: 'Government - Federal'              , id : 'Government - Federal'           } ,
    { name: 'Government - Local, City  , State' , id : 'Government - Local, City, State'} ,
    { name: 'Government - Emergency Response'   , id : 'Government - Emergency Response'} ,
    { name: 'Infrastructure'                    , id : 'Infrastructure'                 } ,
    { name: 'Insurance'                         , id : 'Insurance'                      } ,
    { name: 'Inspection'                        , id : 'Inspection'                     } ,
    { name: 'Interior Design'                   , id : 'Interior Design'                } ,
    { name: 'Landscaping'                       , id : 'Landscaping'                    } ,
    { name: 'Manufacturing'                     , id : 'Manufacturing'                  } ,
    { name: 'Mining'                            , id : 'Mining'                         } ,
    { name: 'Property Management'               , id : 'Property Management'            } ,
    { name: 'Real Estate Development'           , id : 'Real Estate Development'        } ,
    { name: 'Other'                             , id : 'Other'                          } ,
]

Organization.organizationSizes = ['1-10', '11-50', '51-250', '251-1000', '1001-5000', '5000+']

Organization.participants = (organization, includeSuspended) =>
    includeSuspended ? organization.participants : filterObject(p => !p.isSuspended, organization.participants)

/*
 * Return the number of members in the organization, possibly including suspended
 */
Organization.memberCount = (organization, includeSuspended = true) =>
    Object.keys(Organization.participants(organization, includeSuspended)).length

// ---------------------------------------------------------------------------------------------------------------------
// Serialization
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Create an Organization from a JSON object
 * @sig fromFirebase :: {k:v} -> Organization
 */
Organization.fromFirebase = data => {
    // participants will be set only when initially loading the Organization (see loadUserRelatedDataIntoRedux)
    const { participants } = data

    // in other cases, we don't want to override the participants
    const organization = participants ? mergeRight(data, { participants }) : data

    const billingError = () => {
        const { billingError } = organization
        if (!billingError) return undefined // usually there's no billingError at all

        return {
            ...organization.billingError,
            timestamp: timestampToDate(billingError.timestamp),
            gracePeriodEndsTimestamp: timestampToDate(billingError.gracePeriodEndsTimestamp),
        }
    }

    // prettier-ignore
    return Organization.from({
        id                  : organization.id,
        name                : organization.name,
        industry            : organization.industry,
        plan                : organization.plan,
        logo                : organization.logo,
        icon                : organization.icon,
        projectIds          : organization.projects,
        participants        : organization.participants,
        allowAi             : organization.allowAi,
        organizationSize    : organization.organizationSize,
        limitsAndCounts     : organization.limitsAndCounts,

        seatCount           : organization.seatCount,
        billingCycle        : organization.billingCycle,
        nextPaymentTimestamp: timestampToDate(organization.nextPaymentTimestamp),
        billingError        : billingError(),
        
        isDisabled          : organization.isDisabled,
    })
}

Organization.toFirebase = organization =>
    pick(
        ['id', 'name', 'industry', 'plan', 'logo', 'icon', 'participants', 'projects', 'allowAi', 'organizationSize'],
        renameKeys({ projectIds: 'projects' }, organization)
    )

export default Organization
