// prettier-ignore
import StringTypes from '@range.io/basic-types/src/string-types.js'
import { tagged } from '@range.io/functional'
import PropTypes from 'prop-types'
import { TagShapePropTypes } from './tag-shape.js'

// prettier-ignore
const UploadShape = tagged('UploadShape', {
    id                 : StringTypes.Id,
    parentId           : StringTypes.Id,
    createdAt          : 'Object', // Date
    fileSize           : 'Number?', // optional for now because old images won't have it
    fileType           : '/jpeg|png|pdf|docx|xlsx/',
    imageHeightToWidth : 'Number?',
    tags               : '[TagShape]',
    createdBy          : 'ParticipantShape',
    name               : 'String?',
    description        : 'String?',
    correlation        : StringTypes.OptionalId,

})

// prettier-ignore
const UploadShapePropTypes = {
    id                 : PropTypes.string.isRequired,
    parentId           : PropTypes.string.isRequired,
    createdAt          : PropTypes.object.isRequired,
    fileSize           : PropTypes.number,
    fileType           : PropTypes.oneOf(['jpeg', 'png', 'pdf', 'docx', 'xlsx']).isRequired,
    imageHeightToWidth : PropTypes.number,
    tags               : PropTypes.arrayOf(PropTypes.shape(TagShapePropTypes)).isRequired,
    createdBy          : PropTypes.object.isRequired,
    name               : PropTypes.string,
    description        : PropTypes.string,
    correlation        : PropTypes.string,
}

UploadShape.fromUpload = shapeLookupTable => upload => {
    const shape = UploadShape.from({
        id: upload.id,
        parentId: upload.parentId,
        createdAt: upload.createdAt,
        fileSize: upload.fileSize,
        fileType: upload.fileType,
        imageHeightToWidth: upload.imageHeightToWidth,
        tags: upload.tagIds.map(id => shapeLookupTable.tags.get(id)),
        createdBy: shapeLookupTable.projectParticipants.get(upload.createdBy),
        name: upload.name,
        description: upload.description,
        correlation: upload.correlationId,
    })

    shape.parent = () => {
        if (upload.parentType === 'Collaboration') return shapeLookupTable.collaborations[upload.parent]
        if (upload.parentType === 'Feature') return shapeLookupTable.features[upload.parent]

        throw new Error('Upload has no parent')
    }
    return shape
}

export { UploadShape, UploadShapePropTypes }
