import React, { useEffect, useRef, useState } from 'react'
import { useStore } from 'react-redux'
import { v4 } from 'uuid'
import { Button, FlexColumn, FlexRow, Icon, Text } from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'
import { ReduxSelectors } from '../redux/index.js'
import * as Segment from '../segment/segment.js'
import CreatingReportInfoModal from './CreatingReportInfoModal'
import EditTagsModal from './EditTagsModal.js'
import useReport from './reports/Report.js'
import { Pdf } from '@range.io/basic-types'

const MediaViewHeaderWrapper = styled(FlexColumn, {
    color: '$neutral04',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    padding: '16px 16px 16px 16px',
    borderBottom: '1px solid $neutral07',
    background: '$neutral08',
})

const ControlsContainer = styled(FlexRow, {
    justifyContent: 'space-between',
    alignItems: 'center',

    '& > div': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 16,
    },
})

const PhotoSelectedCountLabel = styled('span', {
    color: '$neutral05',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '18px',
})

const MediaViewBulkActions = ({
    onDelete,
    onDownload,
    onCancel,
    onGoToTagManager,
    onTagsAdd,
    onTagsReplace,
    selectedPhotoCount,
    selectedUploads,
    hasRightsToDelete,
    areFiltersActive,
    selectedProject,
}) => {
    const _onCancel = () => {
        onCancel()
        resetReport()
    }

    const [showEditTagsModal, setShowEditTagsModal] = useState()
    const showTagsModal = () => setShowEditTagsModal(true)
    const hideTagsModal = () => setShowEditTagsModal(false)

    const _onCancelEditTags = () => {
        showTagsModal()
        onCancel()
    }

    const handleEditTagsConfirm = (editType, items) => {
        if (editType === 'add') onTagsAdd(items)
        if (editType === 'replace') onTagsReplace(items)
        hideTagsModal()
    }

    const { prepareReport, openReport } = useReport()
    const [isReportInProgress, setIsReportInProgress] = useState(false)
    const reportPromiseRef = useRef(null)
    const { getState } = useStore()

    const resetReport = () => {
        reportPromiseRef?.current && reportPromiseRef.current.cancel()
        setIsReportInProgress(false)
    }

    const handleReportButtonClicked = () => {
        setIsReportInProgress(true)
        // delay it a bit so React gets time to update and show the modal before starting the creation of the PDF
        setTimeout(() => {
            const newReportPromise = prepareReport(selectedUploads)
            reportPromiseRef.current = newReportPromise
            reportPromiseRef.current.promise.then(async reportBlob => {
                const segmentParams = ReduxSelectors.paramsForTrackEvent(getState())
                segmentParams.projectName = selectedProject.name
                segmentParams.taskCount = selectedUploads.length
                segmentParams.pageCount = await Pdf.getPdfPageCount(reportBlob)
                Segment.sendTrack('media report created', v4(), segmentParams)

                openReport(reportBlob)
                setIsReportInProgress(false)
            })
        }, 10)
    }

    useEffect(() => {
        // we want to reset the report whenever the content for it changed (here, the amount of selected uploads)
        resetReport()
    }, [selectedPhotoCount])

    return (
        <MediaViewHeaderWrapper>
            {showEditTagsModal && (
                <EditTagsModal
                    onCancel={hideTagsModal}
                    onConfirm={handleEditTagsConfirm}
                    onDelete={_onCancelEditTags}
                    onGoToTagManager={onGoToTagManager}
                    addTagsDescription="Add additional, new tags to the selected media items."
                    replaceTagsDescription="Replace ALL existing tags with new tags for the selected media items. ALL existing tags will
                    be removed and replaced with new tags."
                />
            )}

            {isReportInProgress && <CreatingReportInfoModal onCancel={resetReport} />}
            <ControlsContainer>
                <div>
                    <PhotoSelectedCountLabel>{`${
                        selectedPhotoCount === 1 ? '1x item selected' : `${selectedPhotoCount}x items selected`
                    }`}</PhotoSelectedCountLabel>
                    <Button variant="primary" size="lg" onClick={handleReportButtonClicked} css={{ minWidth: '166px' }}>
                        {isReportInProgress ? (
                            <Icon name="loading" iconSize="16px" />
                        ) : (
                            <>
                                <Icon name="library" iconSize="16px" />
                                <Text>Generate Report</Text>
                            </>
                        )}
                    </Button>
                    <Button variant="secondary" size="lg" onClick={showTagsModal}>
                        <Icon name="tags" iconSize="16px" />
                        <Text>Bulk Tag Editor</Text>
                    </Button>
                    <Button variant="secondary" size="lg" onClick={onDownload}>
                        <Icon name="download" iconSize="16px" />
                        <Text>Download</Text>
                    </Button>
                    {hasRightsToDelete && (
                        <Button variant="destructiveSecondary" css={{ pr: '12px' }} size="lg" onClick={onDelete}>
                            <Icon name="trash" iconSize="16px" />
                            <Text>Delete</Text>
                        </Button>
                    )}
                </div>
                <Button css={{ ml: 'auto' }} variant="secondary" size="lg" onClick={_onCancel}>
                    <Text>Done</Text>
                </Button>
            </ControlsContainer>
        </MediaViewHeaderWrapper>
    )
}

export default MediaViewBulkActions
