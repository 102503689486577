/*
 * Upload
 *
 * An Upload represents the metadata (but not the actual file) for a user-uploaded resource
 */
import { mergeRight, pick, renameKeys, tagged } from '@range.io/functional'
import { millisOrTimestampToDate } from '../helper/timestamp.js'
import StringTypes from '../string-types.js'
import PhotoAnnotations from './photo-annotations.js'

// ---------------------------------------------------------------------------------------------------------------------
// Definitions
// ---------------------------------------------------------------------------------------------------------------------

// prettier-ignore
const Upload = tagged('Upload', {
    id                : StringTypes.Id,
    createdAt         : 'Object', // Date
    createdBy         : StringTypes.Id,
    name              : 'String?',
    description       : 'String?',
    parentId          : StringTypes.Id,
    parentType        : /Collaboration|Feature/,
    correlationId     : StringTypes.Id,
    tagIds            : StringTypes.Ids, // -> TagName
    fileType          : '/jpeg|png|pdf|docx|xlsx/',
    imageHeightToWidth: 'Number?',
    storageVersion    : 'Object?',
    fileSize          : 'Number',
    annotations       : 'PhotoAnnotations?',
    
    collaborationId   : StringTypes.OptionalId
})

// TODO: remove renames
Upload.prototype.renameFieldButLoudly('parent', 'parentId')
Upload.prototype.renameFieldButLoudly('correlation', 'correlationId')
Upload.prototype.renameFieldButLoudly('tags', 'tagIds')

/*
 * Create an Upload for an existing Collaboration
 * @sig uploadForCollaboration :: (Id, Collaboration, Id, String, Number, String, Number, String, Number, [Id]) -> Upload
 */
Upload.uploadForCollaboration = ({
    id,
    collaboration,
    userId,
    name,
    imageHeightToWidth,
    fileType,
    fileSize,
    correlationId,
    tagIds = [],
}) =>
    Upload.from({
        id,
        createdAt: new Date(),
        createdBy: userId,
        name,
        parentId: collaboration.id,
        parentType: 'Collaboration',
        correlationId: correlationId || id,
        tagIds,
        fileType,
        fileSize,
        imageHeightToWidth,
    })

/*
 * Create a new Upload merging an old one with values from changes
 * @sig update :: (Upload, {k:v}) -> Upload
 */
Upload.update = (upload, changes) => Upload.from(mergeRight(upload, changes))

// ---------------------------------------------------------------------------------------------------------------------
// Serialization
// ---------------------------------------------------------------------------------------------------------------------

/*
 * @sig fromFirebase = {k:v} -> Upload
 */
Upload.fromFirebase = o =>
    Upload.from({
        id: o.id,
        createdAt: millisOrTimestampToDate(o.createdAt),
        createdBy: o.createdBy,
        description: o.description,
        name: o.name,
        parentId: o.parent,
        parentType: o.parentType,
        correlationId: o.correlation,
        tagIds: o.tags,
        fileType: o.fileType,
        fileSize: o.fileSize,
        imageHeightToWidth: o.imageHeightToWidth,
        storageVersion: o.storageVersion,
        annotations: o.annotations && PhotoAnnotations.fromFirebase(o.annotations),

        collaborationId: o.collaborationId,
    })

// Convert upload to the format that is used in the createUploadHandler
Upload.toNewUpload = upload =>
    pick(
        [
            'uploadId',
            'name',
            'description',
            'collaborationId',
            'correlationId',
            'tags',
            'fileType',
            'fileSize',
            'imageHeightToWidth',
            'annotations',
            'tagIds',
        ],
        renameKeys(
            {
                parentId: 'collaborationId',
                tagIds: 'tags',
                annotations: [
                    'annotations',
                    () => upload.annotations && PhotoAnnotations.toFirebase(upload.annotations),
                ],
                id: 'uploadId',
            },
            upload
        )
    )

const mimeTypes = {
    jpeg: 'image/jpeg',
    png: 'image/png',
    pdf: 'application/pdf',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}

/*
 * extension to mime-type mapping
 */
Upload.mimeTypes = mimeTypes

/*
 * Try to guess the fileType from the file data
 */
Upload.guessFileType = file => {
    if (file.type === mimeTypes.jpeg) return 'jpeg'
    if (file.type === mimeTypes.png) return 'png'
    if (file.type === mimeTypes.pdf) return 'pdf'
    if (file.type === mimeTypes.xlsx) return 'xlsx'
    if (file.type === mimeTypes.docx) return 'docx'

    throw new Error("Don't understand fileType", file)
}

/*
 * Is the file an image?
 * @sig isImageFile :: File -> Boolean
 */
Upload.isImageFile = file => file.type === mimeTypes.jpeg || file.type === mimeTypes.png

/*
 * Is the Upload an image?
 * @sig isImageFile :: Upload -> Boolean
 */
Upload.isImage = upload => {
    if (!upload.fileType) throw new Error('no fileType for upload', JSON.stringify(upload))
    return upload.fileType === 'jpeg' || upload.fileType === 'png'
}

/*
 * Will the Upload EVER have a thumbnail?
 * @sig willHaveThumbnail :: Upload -> Boolean
 *
 * For now, the answer is only if it's a JPG or PNG
 */
Upload.willHaveThumbnail = upload => {
    if (!upload.fileType) throw new Error('no fileType for upload', JSON.stringify(upload))
    return upload.fileType === 'jpeg' || upload.fileType === 'png'
}

/*
 * Remove the extension from a file name if it ends in one of our expected extensions
 * @sig dropExtension :: String -> String
 */
Upload.dropExtension = fileName => fileName.replace(/\.(jpg|jpeg|png|pdf|xlsx|docx)$/, '')

/*
 * Return a name for the Upload that depends on its type and extension
 * @sig fileName :: Upload -> String
 */
Upload.fileName = upload =>
    Upload.isImage(upload) ? `img_${upload.id.split('-')[0]}.png` : `${upload.name}.${upload.fileType}`

export default Upload
