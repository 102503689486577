import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { pick, without } from '@range.io/functional'
import {
    Avatar,
    Box,
    Button,
    FlexColumn,
    FlexRow,
    Icon,
    LoadingRingAnimation,
    ScrollArea,
    StatusPill,
    Switch,
    Text,
} from '../components-reusable/index.js'
import { useSearch } from '../components-reusable/hooks/index.js'
import { TableTools } from './TableTools.js'
import { styled } from '../range-theme/index.js'
import { ReduxActions, ReduxSelectors } from '../redux/index.js'
import { useSelector, useStore } from 'react-redux'

const StyledOuterContainer = styled(FlexColumn, {
    height: '100vh',
    width: '100%',
    fontSize: 14,
    ai: 'center',
    justifyContent: 'start-center',
    transitionDuration: '0.4s',
    background: '$neutral10',

    position: 'absolute',
    zIndex: 100,
})

const StyledFlexColumn = styled(FlexColumn, {
    minHeight: '100vh',
    width: '80%',
    maxWidth: '1024px',
    justifyContent: 'start',
    paddingTop: 80,
    paddingBottom: 40,
})

const StyledTopBar = styled(FlexRow, {
    width: '100%',
})

const StyledPrimaryLabel = styled(Box, {
    width: 'auto',
    color: '$neutral04',
    fs: 32,
    fw: '700',
})

const StyledSecondaryLabel = styled(Text, {
    width: 'auto',
    fs: 14,
    fontWeight: '500',
    justifyContent: 'start',
    whiteSpace: 'wrap',

    variants: {
        variant: {
            archived: {
                color: '$neutral05',
            },
        },
    },
})

const StyledTable = styled(FlexColumn, {
    width: '100%',
    background: '$neutral09',
    border: '1px solid $neutral07',
    br: 6,
    overflow: 'hidden',
})

const StyledLabels = styled(FlexColumn, {
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    gap: '4px',
})

const ProjectRow = styled(FlexRow, {
    p: '8px 12px 8px 16px',
    justifyContent: 'start',
    borderBottom: '1px solid $neutral07',
    gap: 16,
    color: '$neutral04',
    alignItems: 'center',

    '&:last-child': { borderBottom: 'none' },
})

const StyledTertiaryLabel = styled(Text, {
    fs: 12,
    color: '$neutral04',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
})

const LoadingOverlay = styled(FlexColumn, {
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    background: '$neutral09',
})

const ToggleAllRow = ({ label, description, isToggleInProgress, projectsCount, checked, onCheckedChange }) => (
    <StyledTable css={{ marginBottom: '16px', marginTop: '16px', flex: '0 0 auto' }}>
        <ProjectRow>
            <Switch
                variant="filter"
                checked={checked}
                onCheckedChange={onCheckedChange}
                disabled={isToggleInProgress}
            />
            <StyledLabels>
                <StyledSecondaryLabel css={{ textTransform: 'capitalize' }}>
                    {label} ({projectsCount})
                </StyledSecondaryLabel>
                <StyledTertiaryLabel css={{ color: '$neutral05' }}>{description}</StyledTertiaryLabel>
            </StyledLabels>
        </ProjectRow>
    </StyledTable>
)

const ProjectListRow = ({ name, checked, setChecked, project, disabled, onClick }) => (
    <ProjectRow>
        <Switch variant="filter" checked={checked} onCheckedChange={setChecked} disabled={disabled} />
        <StyledLabels>
            <FlexRow css={{ gap: 6, alignItems: 'center' }}>
                <StyledSecondaryLabel variant={project.isArchived && 'archived'}>{name}</StyledSecondaryLabel>
                {project.isArchived && <StatusPill>ARCHIVED</StatusPill>}
            </FlexRow>
            <StyledTertiaryLabel css={{ color: '$neutral05' }}>{project.address}</StyledTertiaryLabel>
        </StyledLabels>
    </ProjectRow>
)

const ListViewEmptyArea = ({ onNewProjectClick }) => (
    <FlexColumn css={{ height: 'inherit', alignItems: 'center', justifyContent: 'center', pt: 56, pb: 56 }}>
        <Text css={{ color: '$neutral04', fs: '18px', fw: '600', lh: '24px', mb: '8px' }}>
            There are no projects to configure...🤔
        </Text>
        <Text css={{ color: '$neutral05', fs: '14px', fw: '500', lh: '20px', mb: '16px' }}>
            Get started by adding your organizations first project
        </Text>
        <Button onClick={onNewProjectClick} variant="primary" size="lg">
            <Icon iconSize="18" name="newProject" />
            <Text>New Project</Text>
        </Button>
    </FlexColumn>
)

const ProjectList = ({ hasProjects, isViewEmpty, isLoading, projects, onNewProjectClick, showProject }) => {
    const getView = (hasProjects, isViewEmpty, isLoading, projects, onNewProjectClick, showProject) => {
        if (!hasProjects) return <ListViewEmptyArea onNewProjectClick={onNewProjectClick} />

        if (isViewEmpty)
            return (
                <FlexColumn css={{ height: 'inherit', alignItems: 'center', justifyContent: 'center', pt: 56, pb: 56 }}>
                    <Text css={{ color: '$neutral05', fs: '14px', fw: '500' }}>No matches found.</Text>
                </FlexColumn>
            )

        return (
            <ScrollArea>
                {isLoading && (
                    <LoadingOverlay>
                        <FlexColumn css={{ gap: 32 }}>
                            <LoadingRingAnimation size="xl" variant="secondary" />
                            <Text css={{ color: '$neutral05' }}>Updating project access...</Text>
                        </FlexColumn>
                    </LoadingOverlay>
                )}
                {projects.map(showProject(isLoading))}
            </ScrollArea>
        )
    }

    return (
        <FlexColumn css={{ gap: 16, width: '100%', minHeight: 0 }}>
            <StyledTable>
                <FlexColumn css={{ height: '100%' }}>
                    {getView(hasProjects, isViewEmpty, isLoading, projects, onNewProjectClick, showProject)}
                </FlexColumn>
            </StyledTable>
        </FlexColumn>
    )
}

const PROJECT_TYPES = {
    active: {
        id: 'active',
        name: 'Active Projects',
        toggleAllLabel: 'All active projects',
        toggleAllDescription: 'Enable access to active projects',
    },
    all: {
        id: 'all',
        name: 'All Projects',
        toggleAllLabel: 'All projects',
        toggleAllDescription: 'Enable access to all projects',
    },
    archived: {
        id: 'archived',
        name: 'Archived Projects',
        toggleAllLabel: 'All archived projects',
        toggleAllDescription: 'Enable access to archived projects',
    },
}

const PROJECT_DROPDOWN_FILTER_OPTIONS = Object.values(PROJECT_TYPES).map(el => pick(['id', 'name'], el))

/*
 * Manage Projects Screen
 */
const disabled = {}
const AdminViewManageProjects = ({
    participantShape,
    invitation,
    allProjects,
    currentProjectsIds,
    isTogglingProjectsInProgress,
    onProjectsToggled,
    onClose,
    onToggleAllProjects,
}) => {
    const { dispatch } = useStore()
    const organization = useSelector(ReduxSelectors.selectedOrganization)
    const searchFilterSettings = {
        fields: ['name', 'address'],
    }

    const {
        searchValue,
        setSearchValue,
        filteredItems: filteredProjects,
    } = useSearch({
        ...searchFilterSettings,
        items: allProjects,
    })

    const [dropdownFilterValue, setDropdownFilterValue] = useState('active')

    // Get projects by selected type
    const projectsToDisplay = useMemo(() => {
        switch (dropdownFilterValue) {
            case 'all':
                return filteredProjects
            case 'active':
                return filteredProjects.filter(project => !project.isArchived)
            case 'archived':
                return filteredProjects.filter(project => project.isArchived)
        }
    }, [filteredProjects, dropdownFilterValue])

    const _onProjectToggled = async (id, isOn) => {
        disabled[id] = true // don't let the user click twice
        await onProjectsToggled([id], isOn) // possibly long-running
        disabled[id] = false
        triggerRedraw([id, false]) // otherwise, changing disabled has no visible effect
    }

    const canAddMoreProjects = () => {
        if (!organization.limitsAndCounts) return true
        const { projectCountLimit } = organization.limitsAndCounts
        const currentProjectCount = organization.projectIds.length || 0
        return currentProjectCount < projectCountLimit
    }

    const closeButton = () => (
        <Button onClick={onClose} css={{ width: '70px', mb: '24px' }} variant="primary" size="lg">
            <Text>Close</Text>
        </Button>
    )

    // Manage Projects for
    //   {User}
    // Instructions
    // Project Count
    const pageHeader = (projectCount = 0) => (
        <FlexColumn css={{ gap: 16 }}>
            <FlexColumn css={{ gap: 4, color: '$neutral05' }}>
                <StyledSecondaryLabel>Manage Projects For</StyledSecondaryLabel>
                <FlexRow css={{ ai: 'center', gap: 12, width: '100%', pt: 4 }}>
                    {participantShape && <Avatar participantShape={participantShape} size="40" />}
                    {participantShape && <StyledPrimaryLabel>{participantShape.fullName}</StyledPrimaryLabel>}
                    {invitation && <StyledPrimaryLabel>{invitation.inviteeEmail}</StyledPrimaryLabel>}
                </FlexRow>
                <StyledSecondaryLabel css={{ mt: 8 }}>
                    Configure the projects you would like this person to have access too.
                </StyledSecondaryLabel>
            </FlexColumn>
            <TableTools
                css={{ marginTop: '8px' }}
                canManageParticipants={false}
                dropdownFilterOptions={PROJECT_DROPDOWN_FILTER_OPTIONS}
                dropdownFilterValue={dropdownFilterValue}
                inputWidth={320}
                searchValue={searchValue}
                inputPlaceholder="Search for projects..."
                onDropdownFilterValueChange={setDropdownFilterValue}
                setSearchValue={setSearchValue}
            />
            <StyledTertiaryLabel>{projectCount} projects enabled</StyledTertiaryLabel>
        </FlexColumn>
    )

    const showProject = isLoading => project => {
        const setChecked = isOn => _onProjectToggled(id, isOn) // suspended is off; active is on
        const { id, name } = project
        const isActive = !!currentProjectsIds?.includes(id)

        const onProjectSelected = project => navigate(`../${project.id}`)
        const onClick = () => onProjectSelected(project)

        return (
            <ProjectListRow
                key={id}
                name={name}
                checked={isActive}
                setChecked={setChecked}
                onProjectSelected={onProjectSelected}
                onClick={onClick}
                project={project}
                disabled={isLoading}
            />
        )
    }

    const onNewProjectClick = () => {
        if (!canAddMoreProjects()) dispatch(ReduxActions.showPlanLimitModal())
        else navigate('../createProject')
    }

    const [, triggerRedraw] = useState() // used only to trigger after disabled has changed to false
    const navigate = useNavigate()

    const projectsToDisplayIds = projectsToDisplay.map(project => project.id)
    const areAllDisplayedProjectsActive = !without(currentProjectsIds, projectsToDisplayIds).length

    return (
        <StyledOuterContainer>
            <StyledFlexColumn>
                <StyledTopBar>
                    <FlexColumn css={{ width: 'inherit' }}>
                        {closeButton()}
                        {pageHeader(currentProjectsIds?.length)}
                    </FlexColumn>
                </StyledTopBar>
                {searchValue === '' && (
                    <ToggleAllRow
                        label={PROJECT_TYPES[dropdownFilterValue].toggleAllLabel}
                        description={PROJECT_TYPES[dropdownFilterValue].toggleAllDescription}
                        isToggleInProgress={isTogglingProjectsInProgress}
                        projectsCount={projectsToDisplayIds.length}
                        checked={areAllDisplayedProjectsActive}
                        onCheckedChange={isOn =>
                            onToggleAllProjects(isOn, projectsToDisplay, currentProjectsIds, onProjectsToggled)
                        }
                    />
                )}
                <ProjectList
                    hasProjects={allProjects.length > 0}
                    isViewEmpty={projectsToDisplay.length === 0}
                    isLoading={isTogglingProjectsInProgress}
                    projects={projectsToDisplay}
                    onNewProjectClick={onNewProjectClick}
                    showProject={showProject}
                />
            </StyledFlexColumn>
        </StyledOuterContainer>
    )
}

AdminViewManageProjects.displayName = 'AdminViewManageProjects'

const ProjectPropTypes = {
    id: PropTypes.string.isRequired, // uuid
    name: PropTypes.string.isRequired,
}

AdminViewManageProjects.propTypes = {
    participantShape: PropTypes.object,
    invitee: PropTypes.object,
    allProjects: PropTypes.arrayOf(PropTypes.shape(ProjectPropTypes)).isRequired,
    currentProjectsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    isTogglingProjectsInProgress: PropTypes.bool,
    onToggleAllProjects: PropTypes.func.isRequired,
    onProjectsToggled: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
}

export { ProjectListRow, ToggleAllRow, PROJECT_TYPES, PROJECT_DROPDOWN_FILTER_OPTIONS }
export default AdminViewManageProjects
