import * as SelectPrimitive from '@radix-ui/react-select'
import { mergeRight } from '@range.io/functional'
import PropTypes from 'prop-types'
import React from 'react'
import { styled } from '../range-theme/index.js'
import { Flex, Icon, Text } from './index.js'

const Root = styled(SelectPrimitive.Root, {})

const Trigger = styled(SelectPrimitive.Trigger, {
    all: 'unset',
    display: 'inline-flex',
    ai: 'center',
    justifyContent: 'space-between',
    paddingLeft: 'auto',
    borderRadius: 6,
    padding: '0 10px 0 10px',
    fontSize: 14,
    lineHeight: 1,
    gap: 5,
    backgroundColor: '$neutral09',
    border: '1px solid $neutral07',
    color: '$neutral04',
    cursor: 'pointer',
    boxShadow: '0px 0px 0px #5D81FF50',
    '&::placeholder': { Color: '$neutral05' },
    transitionDuration: '0.2s',

    '&:hover': {
        background: '$primary02',
        border: '1px solid $primary03',
    },
})

const TriggerTwoLabels = styled(SelectPrimitive.Trigger, {
    all: 'unset',
    display: 'inline-flex',
    ai: 'center',
    justifyContent: 'space-between',
    paddingLeft: 'auto',
    borderRadius: 6,
    padding: '0 10px 0 10px',
    fontSize: 14,
    lineHeight: 1,
    gap: 5,
    backgroundColor: '$neutral10',
    border: '1px solid $neutral07',
    color: '$neutral04',
    cursor: 'pointer',
    transitionDuration: '0.2s',
    boxShadow: '0px 0px 0px #5D81FF50',

    '&::placeholder': { Color: '$neutral05' },
    '&:hover': { border: '1px solid $primary03', background: '$primary01', boxShadow: '6px 6px 2px #8B8F9D20' },
    '&:focus': { border: '1px solid $primary04', background: '$primary01' },
    '&:active': { border: '1px solid $primary04', background: '$primary01' },
    '&:disabled': {
        border: '1px solid $neutral07',
        background: '$neutral10',
        color: '$neutral06',
        boxShadow: 'none',
        cursor: 'not-allowed',
    },

    variants: {
        variant: {
            light: {
                backgroundColor: '$neutral09',
                border: '1px solid $neutral07',
                color: '$neutral04',

                '&:hover': {
                    background: '$primary02',
                    border: '1px solid $primary03',
                },
            },
        },
    },
})

const Content = styled(SelectPrimitive.Content, {
    overflow: 'hidden',
    backgroundColor: '$neutral10',
    b: '1px solid $neutral07',
    borderRadius: 8,
    boxShadow: '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
    zIndex: 999,
})

const Viewport = styled(SelectPrimitive.Viewport, {
    padding: 8,
})

const ViewportTwoLabels = styled(SelectPrimitive.Viewport, {
    padding: 8,
    gap: 8,
    display: 'flex',
    flexDirection: 'column',
})

const ItemBase = styled(SelectPrimitive.Item, {
    all: 'unset',
    fontSize: '14px',
    lineHeight: '1.4',
    color: '$neutral05',
    borderRadius: 6,
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px',
    position: 'relative',
    userSelect: 'none',
    cursor: 'pointer',

    '&[data-disabled="true"]': {
        pointerEvents: 'none',
    },

    '&:focus': {
        backgroundColor: '$primary02',
        color: '$neutral04',
    },
})

const Item = styled(ItemBase, {
    height: '37px',
})

const ItemTwoLabels = styled(ItemBase, {
    padding: '8px 10px',
    flexDirection: 'column',
    justifyContent: 'center',
    ai: 'flex-start',
    gap: 2,
    height: 'auto',
    width: 330,
    color: '$neutral04',
})

const LabelTop = styled(Flex, {
    color: '$neutral04',

    '&[data-destructive]': {
        color: '$red03',
    },

    '&[data-disabled="true"]': {
        color: '$neutral06',
    },
})

const LabelBottom = styled(LabelTop, {
    color: '$neutral05',
})

const Select = ({
    css = {},
    triggerCss = {},
    options,
    value,
    onValueChange,
    capitalize,
    'data-cy': dataCy = 'select',
}) => {
    const renderOption = option => {
        return (
            <Item key={option.id} value={option.id}>
                <SelectPrimitive.ItemText>
                    <Text css={{ textTransform: capitalize && 'capitalize' }}>{option.name}</Text>
                </SelectPrimitive.ItemText>
                <SelectPrimitive.ItemIndicator />
            </Item>
        )
    }

    triggerCss = mergeRight({ display: 'flex', flexDirection: 'row', marginRight: 'auto' }, triggerCss)
    const name = options.find(o => o.id === value).name

    return (
        <Root value={value} onValueChange={onValueChange}>
            <Trigger css={css} data-cy={dataCy}>
                <SelectPrimitive.Value>
                    <Text css={{ ...triggerCss, textTransform: capitalize && 'capitalize' }}>{name}</Text>
                </SelectPrimitive.Value>
                <SelectPrimitive.Icon>
                    <Icon name="popupMenuArrowTop" iconSize="10px" />
                </SelectPrimitive.Icon>
            </Trigger>

            <SelectPrimitive.Portal>
                <Content data-cy={`${dataCy}-options`}>
                    <Viewport>
                        {options.map(renderOption)}
                        <SelectPrimitive.Separator />
                    </Viewport>
                    <SelectPrimitive.ScrollDownButton />
                </Content>
            </SelectPrimitive.Portal>
        </Root>
    )
}

const SelectPermissions = ({ css = {}, disabled, triggerCss = {}, options, value, onValueChange, triggerVariant }) => {
    const renderOption = option => {
        return (
            <ItemTwoLabels key={option.id} value={option.id} data-disabled={option.disabled}>
                <LabelTop
                    css={{ fs: 14, fw: 500 }}
                    data-destructive={option.destructive}
                    data-disabled={option.disabled}
                >
                    <SelectPrimitive.ItemText>
                        {option.title || option.name} {option.badge || null}
                    </SelectPrimitive.ItemText>
                </LabelTop>
                <LabelBottom css={{ fs: 12 }} data-destructive={option.destructive} data-disabled={option.disabled}>
                    <SelectPrimitive.ItemText>{option.description}</SelectPrimitive.ItemText>
                    <SelectPrimitive.ItemIndicator />
                </LabelBottom>
            </ItemTwoLabels>
        )
    }

    triggerCss = mergeRight({ display: 'flex', flexDirection: 'row', marginRight: 'auto' }, triggerCss)

    const name = options.find(o => o.id === value)?.name

    return (
        <Root disabled={disabled} value={value} onValueChange={onValueChange}>
            <TriggerTwoLabels css={css} variant={triggerVariant}>
                <SelectPrimitive.Value>
                    <Text css={triggerCss}>{name}</Text>
                </SelectPrimitive.Value>
                <SelectPrimitive.Icon>
                    <Icon name="popupMenuArrowTop" iconSize="10px" />
                </SelectPrimitive.Icon>
            </TriggerTwoLabels>
            <Content>
                <ViewportTwoLabels>{options.map(renderOption)}</ViewportTwoLabels>
                <SelectPrimitive.ScrollDownButton />
            </Content>
        </Root>
    )
}

const selectionShape = { name: PropTypes.string.isRequired, id: PropTypes.string.isRequired }
Select.optionsShape = PropTypes.arrayOf(PropTypes.shape(selectionShape))
Select.propTypes = {
    options: Select.optionsShape,
    value: PropTypes.string.isRequired,
    onValueChange: PropTypes.func,
    triggerCss: PropTypes.object,
}

export default Select
export { SelectPermissions }
