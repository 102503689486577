// global FileReader
import { tagged } from '@range.io/functional'
import * as PdfJsLib from 'pdfjs-dist'

// keep tests & storybook happy by not referencing missing PdfJsLib
if (typeof PdfJsLib !== 'undefined' && PdfJsLib.GlobalWorkerOptions)
    PdfJsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.js'

const Pdf = tagged('Pdf', {
    wrapped: 'Object',
})

/*
 * Return a Pdf
 * @sig fromUrl :: String -> Promise Pdf
 */
Pdf.fromUrl = async url => {
    const wrapped = await PdfJsLib.getDocument(url).promise
    return Pdf(wrapped)
}

/*
 * Return a Pdf from a File
 * @sig fromFile :: File (as defined in the browser) -> Promise Pdf
 */
Pdf.fromFile = async file =>
    new Promise((resolve, reject) => {
        const fileReader = new FileReader()

        // callback for readAsArrayBuffer below
        fileReader.onload = async function () {
            try {
                const typedArray = new Uint8Array(this.result)
                const pdf = await PdfJsLib.getDocument(typedArray).promise
                resolve(Pdf(pdf))
            } catch (e) {
                if (e.message === 'Invalid PDF structure.') reject(new Error(`File ${file.path} is not a PDF file`))
                else reject(e)
            }
        }

        fileReader.readAsArrayBuffer(file)
    })

Pdf.numPages = pdf => pdf.wrapped.numPages

/*
 * Given a PDF file verify if it's valid.
 * Right now we only allow single page PDFs to be uploaded by user, therefore we enforce it here.
 * @sig validatePdf :: PDF -> {warnings: [String], errors: [String]}
 */
Pdf.validatePdf = async pdf => {
    const numPages = pdf.wrapped.numPages
    const errors = []
    if (numPages > 1)
        errors.push(
            'You are trying to upload a file with many pages. Please select single page PDFs as your canvas background'
        )
    return { warnings: [], errors }
}

/*
 * Given a Blob of a PDF file return the number of pages it contains.
 * Returns null if there was something wrong with the file.
 * @sig getPdfPageCount :: Blob => Number
 */
Pdf.getPdfPageCount = async pdfBlob => {
    try {
        // we have to convert to array buffer, because the library couldn't always handle the blob
        const arrayBuffer = await pdfBlob.arrayBuffer()
        const pdf = await PdfJsLib.getDocument({ data: arrayBuffer }).promise
        const pageCount = pdf.numPages
        return pageCount
    } catch (error) {
        console.error('Error loading PDF:', error)
        return null
    }
}

export default Pdf
