import { TimeFormat } from '@range.io/basic-types'
import React from 'react'
import {
    Avatar,
    Box,
    Flex,
    FlexColumn,
    FlexRow,
    Icon,
    Image,
    LoadingDotsAnimation,
    Text,
} from '../components-reusable/index.js'
import PossiblySuspendedUserName from '../components-reusable/PossiblySuspendedUserName.js'
import { styled } from '../range-theme/index.js'
import CollaborationWindowBadge from './CollaborationWindowBadge.js'

const MediaViewItemContainer = styled(Box, {
    width: '100%',
    marginBottom: '12px',
})

const TopSectionContainer = styled(FlexRow, {
    height: '39px',
    display: 'flex',
    alignItems: 'center',
})

const UserNameTimeStamp = styled(Text, {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    width: '100%',
})

const UserNameText = styled(PossiblySuspendedUserName, {
    color: '$neutral04',
    fs: '14px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
})

const UploadDateText = styled(Text, {
    color: '$neutral05',
    fs: '12px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
})

const StyledLocationSnapshotContainer = styled(FlexColumn, {
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '1px solid $neutral07',
    borderTop: '1px solid $neutral07',
    width: '100%',
    boxSizing: 'border-box',
    transitionDuration: '0.4s',
    height: '269px',
    backgroundColor: '$snapshotBackground',
    overflow: 'clip',
})

const LocationRowText = styled(Text, {
    fs: '14px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 'inherit',
    marginRight: 'auto',
    color: '$neutral04',
    transitionDuration: '0.4s',

    variants: {
        empty: {
            true: { color: '$neutral05' },
        },
    },
})

const CollaborationRowText = styled(Text, {
    fs: '18px',
    fontWeight: 500,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    marginRight: 'auto',
    color: '$neutral04',
    transitionDuration: '0.4s',

    variants: {
        empty: {
            true: { color: '$neutral05' },
        },
    },
})

const StyledSnapshotColumn = styled(FlexColumn, {
    gap: 0,
    mb: 16,
    b: '1px solid $neutral07',
    br: 6,
    cursor: 'pointer',
    maxWidth: 402,
    transitionDuration: '0.4s',
    overflow: 'hidden',
    boxShadow: '0px 0px 0px #5D81FF50',
    background: '$neutral09',
    '&:hover': { b: '1px solid $primary03', background: '$primary02', boxShadow: '6px 6px 2px #8B8F9D20' },
    '&:active': { b: '1px solid $primary03', boxShadow: 'none' },
})

const StyledTagItem = styled(FlexRow, {
    border: '1px solid $neutral07',
    background: '$primary02',
    br: '100px',
    padding: '6px 12px 6px 10px',
    fs: '14px',
    width: 'fit-content',
    color: '$neutral04',
    fw: 500,
    ai: 'center',
    gap: 4,
})

const LocationRow = ({ locationLabel }) => {
    return (
        <FlexRow css={{ ai: 'center', gap: '10px', p: 10, br: '0 0 5px 5px', color: '$neutral05' }}>
            <Icon name="levels" iconSize="20" css={{ minWidth: 24 }} />
            <LocationRowText>
                <span>{locationLabel}</span>
            </LocationRowText>
        </FlexRow>
    )
}

const CollaborationRow = ({
    collaborationName,
    collaborationTagNames,
    collaborationUploadCount,
    isArchived,
    isCompleted,
    isTask,
    mapPinType,
    statusNameColor,
    identifierComponent,
}) => {
    const isNameEmpty = collaborationName?.length === 0
    const isTagsEmpty = collaborationTagNames.length > 0
    const getRowText = () => {
        if (isNameEmpty) {
            if (mapPinType === 'task') return 'Untitled task pin'
            if (mapPinType === 'photo') return 'Untitled photo pin'
            return 'Untitled canvas pin'
        } else return collaborationName
    }
    return (
        <FlexColumn css={{ p: '10px 10px 12px 10px', br: '0 0 5px 5px', gap: 12 }}>
            <FlexRow
                css={{
                    ai: 'center',
                    gap: '10px',
                    color: '$neutral05',
                }}
            >
                <Flex css={{ minWidth: 32, minHeight: 32 }}>
                    <CollaborationWindowBadge
                        {...{ isArchived, isCompleted, isTask }}
                        color={statusNameColor}
                        count={collaborationUploadCount}
                    />
                </Flex>
                <FlexColumn
                    css={{
                        overflow: 'hidden',
                    }}
                >
                    <FlexRow css={{ minHeight: 'fit-content' }}>{identifierComponent}</FlexRow>
                    <CollaborationRowText empty={isNameEmpty.toString()}>{getRowText()}</CollaborationRowText>
                </FlexColumn>
            </FlexRow>
            {isTagsEmpty && (
                <FlexRow css={{ gap: '8px', flexWrap: 'wrap', ai: 'center' }}>
                    {collaborationTagNames.map((tagName, idx) => (
                        <StyledTagItem key={`tag-pill-${idx}`}>{tagName}</StyledTagItem>
                    ))}
                </FlexRow>
            )}
        </FlexColumn>
    )
}

const MediaDetailsViewLocationTile = ({
    collaborationName,
    collaborationTagNames,
    collaborationUploadCount,
    createdAt,
    isArchived,
    isCompleted,
    isTask,
    locationLabel,
    locationSnapshotUrl,
    mapPinType,
    onClick,
    statusNameColor,
    participantShape,
    identifierComponent,
}) => {
    return (
        <MediaViewItemContainer>
            <StyledSnapshotColumn onClick={onClick}>
                <CollaborationRow
                    collaborationName={collaborationName}
                    collaborationTagNames={collaborationTagNames}
                    collaborationUploadCount={collaborationUploadCount}
                    isArchived={isArchived}
                    isCompleted={isCompleted}
                    isTask={isTask}
                    mapPinType={mapPinType}
                    statusNameColor={statusNameColor}
                    identifierComponent={identifierComponent}
                />
                <StyledLocationSnapshotContainer>
                    {locationSnapshotUrl ? <Image src={locationSnapshotUrl} /> : <LoadingDotsAnimation />}
                </StyledLocationSnapshotContainer>
                <LocationRow locationLabel={locationLabel} />
            </StyledSnapshotColumn>
            <TopSectionContainer>
                <Avatar size="32" css={{ marginRight: '10px' }} participantShape={participantShape} />
                <UserNameTimeStamp>
                    <UserNameText participantShape={participantShape} />
                    <UploadDateText>{TimeFormat.primary(createdAt)}</UploadDateText>
                </UserNameTimeStamp>
            </TopSectionContainer>
        </MediaViewItemContainer>
    )
}

export default MediaDetailsViewLocationTile
