/*
 * This is the master component for showing the Feature window on the right side of the screen including
 * the generic Feature information and collaborations related to the Feature
 */
import { Collaboration, Comment, Geometry, Upload } from '@range.io/basic-types'
import { equals, without } from '@range.io/functional'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useSelector, useStore } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 } from 'uuid'
import {
    CollaborationChangedCommand,
    CommentAddedCommand,
    DuplicateCollaborationCommand,
    GeometriesChangedCommand,
    GeometriesRemovedCommand,
    UploadAddedCommand,
} from '../firebase/commands/index.js'
import { useCommandHistory } from '../firebase/commands/UndoRedo.js'
import { getImageHeightToWidth } from '../helpers.js'
import { ReduxActions, ReduxSelectors } from '../redux/index.js'
import { uploadFileThunk } from '../redux/slices/redux-actions.js'
import * as Segment from '../segment/segment.js'
import CollaborationWindowDumb from './CollaborationWindowDumb.js'
import Identifier from './Identifier.js'
import { possiblyRecenter } from './Map.js'
import useRecentCollaborations from '../components-reusable/hooks/useRecentCollaborations.js'

const MAX_FILE_SIZE_MB = 20 // TODO: define max accepted file size

// eslint-disable-next-line no-unused-vars
/* global FileReader */
/**
 * Select files. Wacky: instantiate a file input and programmatically click it
 * @sig selectFile :: () -> Promise [OS-File-object]
 */
const selectFile = () =>
    new Promise(resolve => {
        const input = document.createElement('input')
        const mimeTypes = Upload.mimeTypes

        input.type = 'file'
        input.multiple = true
        input.accept = `${mimeTypes.jpeg},${mimeTypes.png},${mimeTypes.pdf},${mimeTypes.xlsx},${mimeTypes.docx}`
        input.onchange = () => resolve(Array.from(input.files))

        // safari sometimes doesn't recognize the user's selection if the input is not in the DOM
        input.style.opacity = 0
        input.style.position = 'absolute'
        const body = document.getElementsByTagName('body')[0]
        body.insertBefore(input, body.firstChild)

        // file dialog closed: there is no onblur for input elements, so use onfocus for body
        const oldBodyFocus = body.onfocus
        body.onfocus = () => {
            body.removeChild(input)
            body.onfocus = oldBodyFocus
        }

        input.click()
    })

const CollaborationWindow = ({ onGoToCanvas }) => {
    const { getRecentCollaborations, registerCollaborationAsRecentlyViewed } = useRecentCollaborations()

    const registerAsRecentlyViewedCollaboration = collaborationId => {
        // If collaboration is already registered as recently viewed, do nothing
        if (getRecentCollaborations().includes(collaborationId)) return

        // Else it means it was not registered yet, because all new collaborations are not registered as recently viewed until:
        // - the title is changed
        // - are specifically opened by user, i.e. clicking pin on the map.
        // This means current collaboration was opened as a side effect of creating it.
        registerCollaborationAsRecentlyViewed(collaborationId)
    }

    const Outbound = CollaborationChangedCommand.Outbound
    const onNameChanged = name => {
        // If user changes the title from empty, there's a chance they edit newly created collaboration,
        // that might not be yet registered as recently viewed.
        name?.length && registerAsRecentlyViewedCollaboration(id)

        return runCommand(Outbound(id, { name }, userId))
    }
    const onDescriptionChanged = description => runCommand(Outbound(id, { description }, userId))
    const onStatusChanged = statusId => runCommand(Outbound(id, { statusId }, userId))
    const onAssigneeChanged = assigneeId => runCommand(Outbound(id, { assigneeId }, userId))
    const onDueDateChanged = dueDate => runCommand(Outbound(id, { dueDate }, userId))
    const onTagsChanged = (tagIds, addTags = false) =>
        runCommand(Outbound(id, { tags: tagIds, addTags }, userId, true /* skipUpdate */))

    const [isLoading, setIsLoading] = useState(false)

    const onSaveComment = (text, isNote) => {
        const comment = isNote
            ? Comment.noteForCollaboration(collaboration, userId, text)
            : Comment.commentForCollaboration(collaboration, userId, text)
        return runCommand(CommentAddedCommand.Outbound(comment))
    }

    /*
     * Create an Upload instance for a given file, and optional Tags to assign to it.
     * Uploads the file to Storage as well.
     * @sig addUpload :: (File, [Id]) -> UploadId
     */
    const addUpload = async (file, customTagIds = null) => {
        try {
            const id = v4()
            const imageHeightToWidth = Upload.isImageFile(file) ? await getImageHeightToWidth(file) : 1
            const upload = Upload.uploadForCollaboration({
                id,
                collaboration,
                userId,
                name: Upload.dropExtension(file.name),
                imageHeightToWidth,
                fileType: Upload.guessFileType(file),
                fileSize: file.size,
                tagIds: customTagIds ?? [],
            })
            runCommand(UploadAddedCommand.Outbound(upload))
            setTimeout(() => setScrollFeedToBottom(true)) // can't wait for upload; have to wait for upload to be added
            dispatch(uploadFileThunk({ id, file, projectId: project.id }))
            return id
        } catch (e) {
            console.error(e)
            alert(`Error while uploading file: ${file.name}`)
        }
    }

    const onAttach = async () => {
        const files = await selectFile()
        files.map(f => addUpload(f))
    }

    const onShare = async () => {
        await navigator.clipboard.writeText(window.location.href)
        dispatch(
            ReduxActions.toastAdded({
                id: window.location.href,
                toastLabel: 'Link copied to clipboard',
                severity: 'success',
                showUndo: false,
            })
        )
    }
    const onDelete = () => runCommand(GeometriesRemovedCommand.Outbound([geometry]))

    const onDuplicate = async collaborationShape => {
        if (pinsLimitReached) return dispatch(ReduxActions.showPlanLimitModal())

        setIsLoading(true)
        await runCommand(DuplicateCollaborationCommand.Outbound(collaborationShape, runCommand))
        setIsLoading(false)
    }

    const onArchive = () => {
        // toggle archived/not-archived
        const updatedGeometry = Geometry.update(geometry, {
            archivedDate: geometry.archivedDate ? undefined : new Date(),
        })

        runCommand(GeometriesChangedCommand.Outbound([updatedGeometry]))
    }
    const onClose = () => {
        dispatch(ReduxActions.geometrySelectionCleared())
        getResources().mapboxDraw?.clearSelection()
    }

    const _onSaveComment = (text, isNote) => {
        onSaveComment(text, isNote)
        setScrollFeedToBottom(true) // show the comment they just added
    }

    const _onConvertToTask = () => {
        const statusName = Collaboration.initialCollaborationStatus
        runCommand(CollaborationChangedCommand.Outbound(collaboration.id, { statusId: statusName }, userId))
    }

    const onMoveToCanvas = canvas => {
        const updatedGeometry = Geometry.update(geometry, { canvasId: canvas.id })
        runCommand(GeometriesChangedCommand.Outbound([updatedGeometry]))
        navigate(`/${workspaceId}/${projectId}/${canvas.id}`)

        // we need to wait until there's a WebGL context
        setTimeout(() => {
            const mapboxMap = getResources().mapboxMap
            possiblyRecenter(collaborationShape, mapboxMap)
        }, 500)
    }

    const onTakeCanvasSnippet = () => dispatch(ReduxActions.showCanvasSnippetMode({ show: true, collaboration }))

    const onFollowClick = () => {
        const newFollowers = collaboration.followers?.includes(user.id)
            ? without(user.id, collaboration.followers)
            : collaboration.followers.concat(user.id)

        return runCommand(Outbound(id, { followers: newFollowers }, userId))
    }

    const onFollowerSelected = participantId => {
        const newFollowers = collaboration.followers?.includes(participantId)
            ? without(participantId, collaboration.followers)
            : collaboration.followers.concat(participantId)

        runCommand(Outbound(id, { followers: newFollowers }, userId))
    }

    const { dispatch, getState } = useStore()
    const { runCommand, getResources } = useCommandHistory()
    const project = useSelector(ReduxSelectors.selectedProject)
    const userId = useSelector(ReduxSelectors.selectedUserId)
    const user = useSelector(ReduxSelectors.selectedUser)
    const projectParticipantShapes = useSelector(ReduxSelectors.selectedProjectParticipantShapesAsArray)

    const collaboration = useSelector(ReduxSelectors.selectedCollaboration)
    const allowDelete = useSelector(ReduxSelectors.isDeleteAllowed('collaboration', collaboration))
    const canFullyUpdateCollaboration = useSelector(ReduxSelectors.isUpdateAllowed('collaboration', collaboration))
    const canUpdateGeometry = useSelector(ReduxSelectors.isUpdateAllowed('geometry'))
    const allowArchive = canUpdateGeometry
    const mapboxMap = getResources().mapboxMap
    const allowDuplicate = canUpdateGeometry && mapboxMap // we can duplicate only if mapboxmap is present

    const collaborationShape = useSelector(ReduxSelectors.collaborationShapeForSelectedCollaboration)
    const { canvases } = useSelector(ReduxSelectors.allShapes)
    const showTimelineDetails = useSelector(ReduxSelectors.showTimelineDetails)
    const { id, tags, dueDate, assignee: assigneeId } = collaboration
    const assignee = assigneeId && ReduxSelectors.selectedProjectParticipantWithId(getState(), assigneeId)
    const statusName = collaboration.statusName && ReduxSelectors.statusNameForCollaboration(getState(), collaboration)
    const organization = useSelector(ReduxSelectors.selectedOrganization)

    const allStatusNames = useSelector(ReduxSelectors.statusNames)

    const availableStatuses = useMemo(() => {
        if (canFullyUpdateCollaboration) return allStatusNames

        return Object.keys(allStatusNames).reduce((acc, statusId) => {
            acc[statusId] = allStatusNames[statusId]

            // mark statuses that complete collaboration as disabled
            if (acc[statusId].isCompleted) {
                acc[statusId].disabled = true
            }

            return acc
        }, {})
    }, [allStatusNames, user, canFullyUpdateCollaboration])

    const pinsLimitReached = useMemo(() => {
        if (!organization.limitsAndCounts) return false
        const { collaborationCount, collaborationCountLimit } = organization.limitsAndCounts
        return collaborationCount >= collaborationCountLimit
    }, [organization])

    const uploadsLimitReached = useMemo(() => {
        if (!organization.limitsAndCounts) return false
        const { uploadStorageBytesCount, uploadStorageBytesLimit } = organization.limitsAndCounts
        return uploadStorageBytesCount >= uploadStorageBytesLimit
    }, [organization])

    const onShowTimelineDetailsChanged = show => dispatch(ReduxActions.showTimelineDetailsChanged(show))
    const [scrollFeedToBottom, setScrollFeedToBottom] = useState()
    const { projectId, workspaceId } = useParams()
    const navigate = useNavigate()
    const mimeTypes = Upload.mimeTypes
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        noClick: true,
        noKeyboard: true,
        noDragEventsBubbling: true,
        maxSize: MAX_FILE_SIZE_MB * 1000000, // max file size in bytes
        accept: {
            [mimeTypes.jpeg]: [],
            [mimeTypes.png]: [],
            [mimeTypes.pdf]: [],
            [mimeTypes.xlsx]: [],
            [mimeTypes.docx]: [],
        },
        onDrop: acceptedFiles => {
            if (uploadsLimitReached) dispatch(ReduxActions.showPlanLimitModal())
            else acceptedFiles.map(f => addUpload(f))
        },
        onDropRejected: () =>
            dispatch(
                ReduxActions.toastAdded({
                    id: window.location.href,
                    toastLabel: 'Some files were rejected from uploading',
                    severity: 'error',
                    showUndo: false,
                })
            ),
    })

    const dropzoneRootProps = getRootProps()
    const dropzoneInputProps = getInputProps()

    useEffect(() => setScrollFeedToBottom(false)) // turn it back off after rendering

    if (!collaboration) return null

    const geometry = ReduxSelectors.geometryForCollaboration(getState(), collaboration)
    const isArchived = !!geometry?.archivedDate
    const currentCanvasId = ReduxSelectors.canvasForCollaboration(getState(), collaboration)?.id
    const nameInputRef = useRef()
    const shouldFocusCollabWindowNameInput = ReduxSelectors.shouldFocusCollabWindow(getState())
    const isFollowing = collaboration.followers?.includes(user.id) || false
    const presentParticipants = ReduxSelectors.presentProjectParticipants(getState())
    const [participantsForFollowersSelector, setParticipantsForFollowersSelector] = useState([])
    useEffect(() => {
        if (shouldFocusCollabWindowNameInput && nameInputRef.current) {
            nameInputRef.current.focus()
            dispatch(ReduxActions.setFocusCollabWindow(false))
        }
    }, [shouldFocusCollabWindowNameInput])

    useEffect(() => {
        const toFollowersSelectorItem = participantShape => {
            const isOnline = !!presentParticipants.find(u => u.id === participantShape.id)
            return {
                value: participantShape.id,
                participantShape,
                isOnline: isOnline || undefined,
            }
        }

        const participantsInFollowerFormat = projectParticipantShapes
            .filter(p => !p.isSuspended) // we don't want to show suspended users on the list
            .map(toFollowersSelectorItem) // map it to follower format, so we get online state and value for the list

        // this prevents resetting the participantsForFollowersSelector each time participants are pulled
        if (!equals(participantsInFollowerFormat, participantsForFollowersSelector)) {
            setParticipantsForFollowersSelector(participantsInFollowerFormat)
        }
    }, [presentParticipants, projectParticipantShapes])

    const collaborationWindowProps = {
        collaborationIdentifierComponent: collaboration.identifier && (
            <Identifier
                projectIdentifier={project.identifier}
                collaborationIdentifier={collaboration.identifier}
                css={{
                    marginTop: 4,
                }}
            />
        ),
        allStatusNames: availableStatuses,
        allowDelete,
        allowDuplicate,
        allowArchive,
        archiveText: isArchived ? 'Unarchive' : 'Archive',
        assignee,
        canvases,
        collaborationShape,
        currentCanvasId,
        dueDate,
        geometry,
        isArchived,
        projectParticipantShapes,
        showTimelineDetails,
        statusName,
        tags,
        user,
        scrollFeedToBottom,
        nameInputRef,
        isLoading,
        uploadsLimitReached,
        participantsForFollowersSelector,
        isFollowing,
        onFollowClick,
        onFollowerSelected,

        maxFileSize: MAX_FILE_SIZE_MB,
        dropzoneRootProps,
        dropzoneInputProps,
        isDragActive,

        onNameChanged,
        onDescriptionChanged,
        onTagsChanged,
        onStatusChanged: Collaboration.isTask(collaboration) ? onStatusChanged : undefined,
        onConvertToTask: Collaboration.isTask(collaboration) ? undefined : _onConvertToTask,
        onAttach,
        onDelete,
        onDuplicate,
        onArchive,
        onShare,
        onClose,
        onSaveComment: _onSaveComment,
        onShowTimelineDetailsChanged,
        onDueDateChanged,

        onAssigneeChanged,
        onGoToCanvas,
        onMoveToCanvas,
        onTakeCanvasSnippet,
    }

    const params = ReduxSelectors.paramsForCollaborationTrackEvent(getState())
    if (params) {
        params.projectName = project.name
        Segment.sendTrack('collaboration viewed', collaboration.id, params)
    }

    return currentCanvasId ? <CollaborationWindowDumb {...collaborationWindowProps} /> : null
}

export default CollaborationWindow
