/*
 * The Feed section shows "Feed Rows" each of which is either an Update, Comment or Upload (or data from Collaboration)
 * There's a lot of subtle functionality in the handling of different row types
 *
 * Formatting for each type of Feed item is different, so there is a separate component for each
 * Figma: https://www.figma.com/file/ETk0Gj8Vkkwz08S0pwGpnz/%F0%9F%92%8ERange-DS-(V2)?node-id=3465%3A95764
 */
import { Collaboration, DateFormat, TimeFormat, Update, Upload } from '@range.io/basic-types'
import * as F from '@range.io/functional'
import { pluck, without } from '@range.io/functional'
import React, { useState } from 'react'
import { useSelector, useStore } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { URL_SEARCH_ORDER, useImageUrl } from '../components-reusable/hooks/useImageUrl.js'
import {
    Avatar,
    Box,
    Button,
    DropdownMenuWithTooltip,
    Flex,
    FlexColumn,
    Icon,
    Link,
    LozengeText,
    MediaIcon,
    RowBox,
    SmallText,
    Text,
} from '../components-reusable/index.js'
import PossiblySuspendedUserName from '../components-reusable/PossiblySuspendedUserName.js'
import {
    CommentChangedCommand,
    CommentRemovedCommand,
    UploadChangedCommand,
    UploadRemovedCommand,
} from '../firebase/commands/index.js'
import { useCommandHistory } from '../firebase/commands/UndoRedo.js'
import { downloadImage } from '../helpers.js'
import { styled } from '../range-theme/index.js'
import { CollaborationShape } from '../react-shapes/collaboration-shape.js'
import { CommentShape } from '../react-shapes/comment-shape.js'
import { UpdateShape } from '../react-shapes/update-shape.js'
import { UploadShape } from '../react-shapes/upload-shape.js'
import { ReduxActions, ReduxSelectors } from '../redux/index.js'
import CollaborationWindowNote from './CollaborationWindowNote.js'
import CommentFeedItem from './CommentFeedItem.js'
import Tags from './Tags.js'
import { VersionPill } from './VersionDropdown.js'

// ---------------------------------------------------------------------------------------------------------------------
// Functions
// ---------------------------------------------------------------------------------------------------------------------

const avatar = u => <Avatar size="32" url={u.avatarUrl} fallbackText={u.avatarFallbackText} css={{ mr: '8px' }} />

/*
 * We DON'T want to show the first DataChanged event for name or description, so we filter them out
 * @sig filterOutFirstDataChangedEvents :: [FeedRow] -> [FeedRow]
 */
const filterOutFirstDataChangedEvents = feedRows => {
    // find the FIRST Update with a change from no value to some value for name and/or description
    const firstNameChange = feedRows.find(r => Update.is(r) && !r.oldValue && r.field === 'name')
    const firstDescChange = feedRows.find(r => Update.is(r) && !r.oldValue && r.field === 'description')

    // remove them
    return without([firstNameChange, firstDescChange], feedRows)
}

// ---------------------------------------------------------------------------------------------------------------------
// Styled
// ---------------------------------------------------------------------------------------------------------------------

// Overall style for the FeedSection
// prettier-ignore
const StyledFeedSection = styled(Box, {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 24px 24px 24px',
    gap: '16px',
    flexBasis: '100%',

})

// If the name/description changed, you can elect to see the previous value -- which has this grayed-out background box
const StyledPreviousTextBackground = styled(Box, {
    display: 'inlineFlex',
    backgroundColor: '$neutral08',
    border: '1px solid $neutral07',
    borderRadius: '6px',
    padding: '10px',
    color: '$neutral04',
    fontStyle: 'italic',
})

// Styled circle indicator used to represent secondary feed update rows
const StyledCircleIndicator = styled(Flex, {
    minWidth: '8px',
    minHeight: '8px',
    backgroundColor: '$neutral06',
    br: '1000px',
    mr: '21px',
    outline: '0.5px solid $neutral09',
})

// ---------------------------------------------------------------------------------------------------------------------
// Relatively Generic Subcomponents
// ---------------------------------------------------------------------------------------------------------------------

// The user's name in large text (eg. <LargeNameText>Jeff</LargeNameText><SmallNameText>completed...</SmallNameText>
const LargeNameText = ({ participantShape, color = '$neutral04' }) => {
    const css = { fontWeight: 'bold', whiteSpace: 'nowrap', color, pr: '4px', fs: 14 }
    return <PossiblySuspendedUserName css={css} participantShape={participantShape} />
}

// The user's name in small text (eg. <SmallNameText>Jeff</SmallNameText><SmallNameText>completed...</SmallNameText>
const SmallNameText = ({ participantShape }) => {
    const css = { fs: 12, fontWeight: 'bold', color: '$neutral05', pr: '4px' }
    return <PossiblySuspendedUserName css={css} participantShape={participantShape} />
}

// ---------------------------------------------------------------------------------------------------------------------
// Subcomponents for Comments
// ---------------------------------------------------------------------------------------------------------------------

// Three-dot dropdown for Delete/Edit Upload IF the user created the upload in the first place
const ModifyUploadButton = ({ upload, onDelete, onDownloadClick }) => {
    const isEditingAllowed = useSelector(ReduxSelectors.isUpdateAllowed('upload', upload))
    const { dispatch } = useStore()

    const documentType = Upload.isImage(upload) ? 'photo' : 'document'

    const callDeleteModal = () => {
        const title = `Are you sure you want to delete this ${documentType}?`
        const description = `You cannot undo this action.`
        dispatch(
            ReduxActions.globalModalDataSet({
                type: 'destructive',
                title,
                description,
                cancelButton: {
                    label: 'Cancel',
                },
                submitButton: {
                    label: 'Delete',
                    onClick: onDelete,
                },
            })
        )
    }

    return (
        <DropdownMenuWithTooltip.Root>
            <DropdownMenuWithTooltip.Trigger tooltipText="More Actions">
                <Button css={{ w: 32, color: '$neutral05' }} variant="dropdown-trigger">
                    <Icon iconSize="28px" name="threeDot" />
                </Button>
            </DropdownMenuWithTooltip.Trigger>
            <DropdownMenuWithTooltip.Content side="bottom" align="end" sideOffset={6}>
                <DropdownMenuWithTooltip.MenuItem>
                    <Button variant="dropdown-menuitem" onClick={onDownloadClick}>
                        <Icon iconSize="16px" name="download" css={{ mr: 8 }} />
                        <Text>Download</Text>
                    </Button>
                </DropdownMenuWithTooltip.MenuItem>
                {isEditingAllowed && (
                    <DropdownMenuWithTooltip.MenuItem>
                        <Button variant="dropdown-menuitem-destructive" onClick={callDeleteModal}>
                            <Icon iconSize="16px" name="trash" css={{ mr: 8 }} />
                            <Text>Delete</Text>
                        </Button>
                    </DropdownMenuWithTooltip.MenuItem>
                )}
            </DropdownMenuWithTooltip.Content>
        </DropdownMenuWithTooltip.Root>
    )
}

// ---------------------------------------------------------------------------------------------------------------------
// Subcomponents for individual row types
// ---------------------------------------------------------------------------------------------------------------------

// avatar largeText(userName) smallText(created this collaboration 4 days ago)
const CollaborationCreatedRow = ({ collaborationShape }) => {
    return (
        <RowBox css={{ ai: 'center', mt: '8px', mb: '12px', zIndex: 1 }}>
            {avatar(collaborationShape.createdBy)}
            <RowBox css={{ ai: 'baseline', flexWrap: 'wrap' }}>
                <LargeNameText participantShape={collaborationShape.createdBy} />
                <SmallText>{'created this pin ' + TimeFormat.tertiary(collaborationShape.createdAt)}</SmallText>
            </RowBox>
        </RowBox>
    )
}

const IssueCompletedRow = ({ updateShape }) => {
    const css = { color: '$green03', ml: 1, mr: '8px', minWidth: '32px', minHeight: '32px', zIndex: 2 }
    return (
        <RowBox css={{ ai: 'center', mb: '12px', zIndex: 2 }} data-cy="issue-complete">
            <Icon iconSize="32px" name="badge" css={css} />
            <RowBox css={{ ai: 'baseline', flexWrap: 'wrap' }}>
                <LargeNameText color="$green03" participantShape={updateShape.createdBy} />
                <SmallText css={{ color: '$green03' }}>
                    {'completed this task ' + TimeFormat.tertiary(updateShape.createdAt)}
                </SmallText>
            </RowBox>
        </RowBox>
    )
}

const ConvertToTaskRow = ({ updateShape }) => {
    const css = {
        background: '$neutral08',
        mr: '8px',
        minWidth: '34px',
        minHeight: '34px',
        outline: '2px solid $neutral09',
        border: '1px solid $neutral07',
        zIndex: 2,
        br: 999,
        ai: 'center',
    }
    return (
        <RowBox css={{ whiteSpace: 'nowrap', ai: 'center', mb: '8px', zIndex: 1 }} data-cy="status-changed">
            <Flex css={css}>
                <Icon iconSize="28px" name="taskCollaboration" css={{ color: '$neutral05', zIndex: 2 }} />
            </Flex>
            <RowBox css={{ flexWrap: 'wrap', alignItems: 'baseline' }}>
                <LargeNameText participantShape={updateShape.createdBy} />
                <SmallText>{'converted this pin to a task'}</SmallText>
                <SmallText>{TimeFormat.secondary(updateShape.createdAt)}</SmallText>
            </RowBox>
        </RowBox>
    )
}

const StatusChangedRow = ({ statusNames, updateShape }) => {
    const statusName = statusNames[updateShape.newValue]

    return (
        <RowBox
            css={{ whiteSpace: 'nowrap', ai: 'start', mb: '8px', ml: '12.5px', zIndex: 1 }}
            data-cy="status-changed"
        >
            <StyledCircleIndicator css={{ mt: '6.5px' }} />
            <RowBox css={{ flexWrap: 'wrap', minHeight: '22px', alignItems: 'center' }}>
                <SmallNameText participantShape={updateShape.createdBy} />
                <SmallText>{'changed the status to '}</SmallText>
                <LozengeText statusName={statusName} />
                <SmallText>{TimeFormat.secondary(updateShape.createdAt)}</SmallText>
            </RowBox>
        </RowBox>
    )
}

/*
 * View a Comment, including editing and deleting it
 */
const CommentRow = ({ commentShape }) => {
    const { dispatch } = useStore()

    const onDelete = () => runCommand(CommentRemovedCommand.Outbound(commentShape.isNote, commentShape.id))
    const onSave = value =>
        runCommand(CommentChangedCommand.Outbound(commentShape.id, { text: value, textIsEdited: true }))
    const isEditingAllowed = useSelector(ReduxSelectors.isUpdateAllowed('comment', commentShape)) // CommentRow is already "smart"

    const callDeleteModal = () => {
        const title = `Are you sure you want to delete this comment?`
        const description = `You cannot undo this action.`
        dispatch(
            ReduxActions.globalModalDataSet({
                type: 'destructive',
                title,
                description,
                cancelButton: {
                    label: 'Cancel',
                },
                submitButton: {
                    label: 'Delete',
                    onClick: onDelete,
                },
            })
        )
    }
    const { runCommand } = useCommandHistory()
    return (
        <CommentFeedItem
            text={commentShape.text}
            textIsEdited={commentShape.textIsEdited}
            isEditingAllowed={isEditingAllowed}
            onDelete={callDeleteModal}
            onSave={onSave}
            participantShape={commentShape.createdBy}
            createdAt={commentShape.createdAt}
        />
    )
}

/*
 * View a Note, including editing and deleting it
 */
const NoteRow = ({ user, commentShape }) => {
    const { dispatch } = useStore()

    const onDelete = () => runCommand(CommentRemovedCommand.Outbound(commentShape.isNote, commentShape.id))
    const onNoteCompleted = isCompleted => setNoteComplete(commentShape, isCompleted)
    const onNoteChanged = text => runCommand(CommentChangedCommand.Outbound(commentShape.id, { text }))
    const setNoteComplete = (comment, isCompleted) =>
        runCommand(
            CommentChangedCommand.Outbound(comment.id, {
                completedById: isCompleted ? user.id : undefined,
                completedDate: isCompleted ? new Date() : undefined,
            })
        )

    const { runCommand } = useCommandHistory()
    const isEditingAllowed = useSelector(ReduxSelectors.isUpdateAllowed('comment', commentShape))

    const callDeleteModal = () => {
        const title = `Are you sure you want to delete this note?`
        const description = `You cannot undo this action.`
        dispatch(
            ReduxActions.globalModalDataSet({
                type: 'destructive',
                title,
                description,
                cancelButton: {
                    label: 'Cancel',
                },
                submitButton: {
                    label: 'Delete',
                    onClick: onDelete,
                },
            })
        )
    }

    const noteProps = { commentShape, isEditingAllowed, onNoteCompleted, onNoteChanged, onNoteDeleted: callDeleteModal }

    return <CollaborationWindowNote key={commentShape.id} {...noteProps} />
}

// avatar largeText(userName) smallText(4 days ago)
const UploadRow = ({ uploadShape, collaborationShape }) => {
    const onTagsChanged = tags => {
        const tagIds = pluck('id', tags)
        runCommand(UploadChangedCommand.Outbound(uploadShape.id, { tagIds }))
    }
    const onDelete = () => runCommand(UploadRemovedCommand.Outbound(uploadShape.id))

    const handleUploadClicked = () => {
        const collaborationUploadIds = collaborationShape.uploads
            .sort((a, b) => b.createdAt - a.createdAt) // order photos from oldest to newest, so most recent photos are first
            .map(u => u.id) // we only need ids

        navigate(`../media/${uploadShape.id}`, {
            state: {
                safeToNavigateBack: true,
                uploadIds: collaborationUploadIds,
                navigatedFrom: 'collaborationWindow',
                navigatedFromUrl: window.location.pathname + window.location.search,
            },
        })
    }

    const { getState } = useStore()
    const navigate = useNavigate()
    const { runCommand } = useCommandHistory()
    const { createdAt, description, tags, name } = uploadShape
    const { url } = useImageUrl(URL_SEARCH_ORDER.ANNOTATED, uploadShape.id)

    const correlatedUploadsIds = F.pluck('id', ReduxSelectors.correlatedUploads(getState(), uploadShape))
    const version = correlatedUploadsIds.length > 1 ? correlatedUploadsIds.indexOf(uploadShape.id) : null // only add versioning if there is more than one correlated upload
    const authorShape = uploadShape.createdBy

    const commentsCount = ReduxSelectors.commentShapesForUpload(getState(), uploadShape)?.length

    return (
        <FlexColumn css={{ width: '100%', gap: '4px', zIndex: 1 }}>
            <RowBox css={{ ai: 'center', h: 'px' }}>
                {avatar(authorShape)}
                <FlexColumn css={{ gap: '2px', width: '100%' }}>
                    <LargeNameText participantShape={authorShape} />
                    <SmallText>{TimeFormat.primary(createdAt)}</SmallText>
                </FlexColumn>
                <ModifyUploadButton
                    upload={uploadShape}
                    onDelete={onDelete}
                    onDownloadClick={() => downloadImage(url, Upload.fileName(uploadShape))}
                />
            </RowBox>
            <RowBox css={{ ml: '40px', flexDirection: 'column', position: 'relative' }}>
                <RowBox onClick={handleUploadClicked}>
                    <MediaIcon
                        fileType={uploadShape.fileType}
                        fileSize={uploadShape.fileSize}
                        description={description}
                        url={url}
                        name={name}
                        commentsCount={commentsCount}
                    />
                    <div style={{ position: 'absolute', top: '18px', left: '12px', margin: 'auto' }}>
                        <VersionPill version={version} />
                    </div>
                </RowBox>
                <RowBox>
                    <Tags tags={tags} onTagsChanged={onTagsChanged} />
                </RowBox>
            </RowBox>
        </FlexColumn>
    )
}

// Name or description changed; user can show/hide previous value
// smallText(userName) changed the Name field link(show/hide previous) 4 days ago)
const DataChangedRow = ({ updateShape }) => {
    const toggleShowing = () => setShowingPreviousValue(!showingPreviousValue) // show/hide the previous value

    const [showingPreviousValue, setShowingPreviousValue] = useState(false)
    const { createdAt, field } = updateShape

    const css = {
        mb: showingPreviousValue ? '8px' : '8px',
        minHeight: '22px',
        alignItems: 'start',
        ml: '12.5px',
        zIndex: 0,
    }

    const userCss = { fontSize: '12px', fontWeight: 'medium', color: '$neutral05', pr: '4px', fw: 'bold' }
    return (
        <RowBox css={css} data-cy="data-changed">
            <StyledCircleIndicator css={{ mt: '4.5px' }} />
            <RowBox css={{ flexWrap: 'wrap', alignItems: 'center' }}>
                <PossiblySuspendedUserName css={userCss} participantShape={updateShape.createdBy} />
                <SmallText>
                    {`changed the ${field}`} {TimeFormat.secondary(createdAt)}
                </SmallText>
                <Link data-cy="show-previous" onClick={toggleShowing}>
                    {showingPreviousValue ? 'Hide previous' : 'Show previous'}
                </Link>
                {showingPreviousValue && (
                    <StyledPreviousTextBackground css={{ mb: '8px', width: '100%', mt: '8px' }} data-cy="data-changed">
                        <SmallText css={{ color: '$neutral04' }}>&#8220;{updateShape.oldValue}&#8221;</SmallText>
                    </StyledPreviousTextBackground>
                )}
            </RowBox>
        </RowBox>
    )
}

const SmallTextName = ({ participantShape }) => {
    const css = { fontSize: '12px', fontWeight: 'medium', color: '$neutral05', pr: '4px', fw: 'bold' }
    return <PossiblySuspendedUserName css={css} participantShape={participantShape} />
}

const AssigneeChangedRow = ({ updateShape }) => {
    const css = { mb: '8px', minHeight: '22px', alignItems: 'start', ml: '12.5px', zIndex: 0 }

    const computeText = () => {
        if (!updateShape.oldValue)
            return (
                <>
                    <SmallText>assigned to</SmallText>
                    <SmallNameText participantShape={updateShape.assignee} />
                </>
            )
        if (updateShape.newValue)
            return (
                <>
                    <SmallText>changed the assignee to </SmallText>
                    <SmallNameText participantShape={updateShape.assignee} />
                </>
            )
        return <SmallText>removed the assignee</SmallText>
    }

    return (
        <RowBox css={css} data-cy="data-changed">
            <StyledCircleIndicator css={{ mt: '4.5px' }} />
            <RowBox css={{ flexWrap: 'wrap', alignItems: 'center' }}>
                <SmallTextName participantShape={updateShape.createdBy} />
                {computeText()}
                <SmallText>{TimeFormat.secondary(updateShape.createdAt)}</SmallText>
            </RowBox>
        </RowBox>
    )
}

const DueDateChangedRow = ({ updateShape }) => {
    const css = {
        mb: '8px',
        minHeight: '22px',
        alignItems: 'start',
        ml: '12.5px',
        zIndex: 0,
    }

    const computeText = () => {
        if (!updateShape.oldValue) return `set the due date to ${DateFormat.primary(updateShape.newValue)}`
        if (updateShape.newValue) return `changed the due date to ${DateFormat.primary(updateShape.newValue)}`
        return `removed the due date`
    }

    return (
        <RowBox css={css} data-cy="data-changed">
            <StyledCircleIndicator css={{ mt: '4.5px' }} />
            <RowBox css={{ flexWrap: 'wrap', alignItems: 'center' }}>
                <SmallNameText participantShape={updateShape.createdBy} />
                <SmallText>{`${computeText()} ${TimeFormat.secondary(updateShape.createdAt)}`}</SmallText>
            </RowBox>
        </RowBox>
    )
}

// ---------------------------------------------------------------------------------------------------------------------
// Main
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Main component to render the FeedSection
 * @sig CollaborationFeedSection :: ({ updates: [Update], comments: [Comment], uploads: [Upload] }) -> ReactComponent
 */
const CollaborationWindowFeedSection = React.memo(props => {
    const { user, showTimelineDetails = true, collaborationShape, statusNames } = props
    const orderFeedRowsByTime = () => {
        let feedRows = collaborationShape.updates.concat(collaborationShape.comments).concat(collaborationShape.uploads)
        feedRows = filterOutFirstDataChangedEvents(feedRows)
        feedRows = F.sort((a, b) => a.createdAt - b.createdAt, feedRows)
        feedRows.unshift(collaborationShape)
        return feedRows
    }

    const renderFeedRow = (feedRow, i) => {
        const isConvertToTask = feedRow.field === 'statusName' && feedRow.oldValue === undefined
        const isStatusChange = feedRow.field === 'statusName'
        const isAssigneeChange = feedRow.field === 'assignee'
        const isDueDateChange = feedRow.field === 'dueDateTimestamp'
        const isIssueCompleted = isStatusChange && feedRow.newValue === Collaboration.completedCollaborationStatus

        if (UploadShape.is(feedRow)) return <UploadRow uploadShape={feedRow} collaborationShape={collaborationShape} />
        if (CommentShape.is(feedRow) && feedRow.isNote) return <NoteRow user={user} commentShape={feedRow} />
        if (CommentShape.is(feedRow)) return <CommentRow commentShape={feedRow} />
        if (CollaborationShape.is(feedRow)) return <CollaborationCreatedRow collaborationShape={feedRow} />
        if (UpdateShape.is(feedRow) && isIssueCompleted) return <IssueCompletedRow updateShape={feedRow} />
        if (UpdateShape.is(feedRow) && isConvertToTask) return <ConvertToTaskRow updateShape={feedRow} />

        if (showTimelineDetails) {
            const isUpdateShape = UpdateShape.is(feedRow)
            if (isUpdateShape && isStatusChange)
                return <StatusChangedRow updateShape={feedRow} statusNames={statusNames} />
            if (isUpdateShape && isAssigneeChange) return <AssigneeChangedRow updateShape={feedRow} />
            if (isUpdateShape && isDueDateChange) return <DueDateChangedRow updateShape={feedRow} />
            if (isUpdateShape) return <DataChangedRow updateShape={feedRow} />
        }
    }

    const renderFeedRowWrapper = (feedRow, i) => {
        const row = renderFeedRow(feedRow, i)

        return row ? (
            <Box css={{ zIndex: 100 + feedRowCount - i }} key={`feed-row-${i}`}>
                {row}
            </Box>
        ) : null
    }

    const feedRows = orderFeedRowsByTime()
    const divRef = React.useRef()
    const feedRowCount = feedRows.length

    return <StyledFeedSection ref={divRef}>{feedRows.map(renderFeedRowWrapper)}</StyledFeedSection>
})

export default CollaborationWindowFeedSection
