import { CanvasSource } from '@range.io/basic-types'
import { equals } from '@range.io/functional'
import React, { useMemo } from 'react'
import { useSelector, useStore } from 'react-redux'

import useNavigateWithQuery from '../components-reusable/hooks/useNavigateWithQuery.js'
import useRecentCollaborations from '../components-reusable/hooks/useRecentCollaborations.js'
import { FlexColumn, FlexRow, Icon, IconButtonWithTooltip } from '../components-reusable/index.js'
import { QrCode } from '../components-reusable/QrCode.js'
import Select from '../components-reusable/Select.js'
import { useCommandHistory } from '../firebase/commands/UndoRedo.js'
import { styled } from '../range-theme/index.js'
import { ReduxActions, ReduxSelectors } from '../redux/index.js'
import BackgroundMapboxMap from './BackgroundMapboxMap.js'
import BackgroundPdfMap from './BackgroundPdfMap.js'
import CollaborationWindow from './CollaborationWindow.js'
import ControlBar from './ControlBar.js'
import DrawingToolsToolbar from './DrawingToolsToolbar.js'
import Map from './Map.js'
import MapboxDrawingCanvas from './MapboxDrawingCanvas.js'
import MapLayerPresence from './MapLayerPresence.js'
import MapPopupController from './MapPopupController.js'
import { NavigatorWrapper } from './Navigator.js'
import PageLoading from './PageLoading.js'
import PhotoMarkerController from './PhotoMarkerController.js'
import ProjectPage from './ProjectPage.js'

// ---------------------------------------------------------------------------------------------------------------------
// Components
// ---------------------------------------------------------------------------------------------------------------------

const CollaborationWindowContainer = props => {
    const { mapboxMap, ...otherProps } = props // need to AVOID passing mapboxMap down or React will complain
    const css = {
        zIndex: '0.5',
        position: 'absolute',
        top: '12px',
        right: '12px',
        bottom: '12px',
        minWidth: '500px',
        maxWidth: '500px',
        width: '500px',
    }
    return <FlexRow css={css} {...otherProps} />
}

/*
 * Tools INSIDE the map mar at the top-left: background selector and drawing tools
 */
const TopLeftTools = ({ canvasSources, selectedCanvasSource, showCollaborationTools, canvasSourceChanged }) => {
    const selectedTool = useSelector(ReduxSelectors.selectedTool)
    const organization = useSelector(ReduxSelectors.selectedOrganization)

    const pinsLimitReached = useMemo(() => {
        if (!organization.limitsAndCounts) return false
        const { collaborationCount, collaborationCountLimit } = organization.limitsAndCounts
        return collaborationCount >= collaborationCountLimit
    }, [organization])

    const uploadsLimitReached = useMemo(() => {
        if (!organization.limitsAndCounts) return false
        const { uploadStorageBytesCount, uploadStorageBytesLimit } = organization.limitsAndCounts
        return uploadStorageBytesCount >= uploadStorageBytesLimit
    }, [organization])

    return (
        <FlexRow
            css={{
                display: 'flex',
                position: 'absolute',
                h: '40px',
                top: '12px',
                gap: '12px',
                margin: '0 12px',
                alignItems: 'center',
            }}
        >
            {showCollaborationTools && (
                <DrawingToolsToolbar
                    selectedTool={selectedTool}
                    pinsLimitReached={pinsLimitReached}
                    uploadsLimitReached={uploadsLimitReached}
                />
            )}

            {canvasSources.length > 1 && (
                <CanvasSourceSelector
                    canvasSources={canvasSources}
                    selectedCanvasSource={selectedCanvasSource}
                    canvasSourceChanged={canvasSourceChanged}
                />
            )}
        </FlexRow>
    )
}

const StyledToolbar = styled(FlexColumn, {
    position: 'absolute',
    border: `1px solid $neutral07`,
    backgroundColor: '$neutral10',
    borderRadius: 6,
    overflow: 'hidden',
})

// see DrawingToolsToolbar to understand how this works
const ToolbarButton = props => (
    <IconButtonWithTooltip
        size={props.size}
        iconName={props.iconName}
        side="right"
        tooltipText={props.tooltipText}
        variant="toolbar"
        onClick={props.onClick}
        corners={props.$corners}
    />
)

/*
 * Button toolbar for Zoom In|Center|Zoom Out
 */
export const MapLayerZoomControls = props => {
    const zoomIn = () => mapboxMap.zoomIn()
    const zoomOut = () => mapboxMap.zoomOut()
    const recenter = () =>
        mapboxMap.jumpTo({ center: canvasSource.center, zoom: CanvasSource.getDefaultZoom(canvasSource) })

    const { mapboxMap, canvasSource } = props

    return (
        <StyledToolbar css={{ bottom: '12px', left: '12px' }}>
            <ToolbarButton size="28px" tooltipText="Zoom in" iconName="zoomIn" onClick={zoomIn} />
            <ToolbarButton size="28px" tooltipText="Center canvas" iconName="centerMap" onClick={recenter} />
            <ToolbarButton size="28px" tooltipText="Zoom out" iconName="zoomOut" onClick={zoomOut} />
        </StyledToolbar>
    )
}

/*
 * Button toolbar for switching canvas forwards and backwards
 */
const CanvasSwitchControls = ({ onSelectCanvas, selectedCanvas, selectedProject }) => {
    const handlePreviousClicked = () => {
        const currentCanvasIndex = selectedProject.canvasOrder.indexOf(selectedCanvas.id)
        const totalCanvasCount = selectedProject.canvasOrder.length
        const prevCanvasIndex = (currentCanvasIndex - 1 + totalCanvasCount) % totalCanvasCount
        onSelectCanvas(selectedProject.canvasOrder[prevCanvasIndex])
    }

    const handleNextClicked = () => {
        const currentCanvasIndex = selectedProject.canvasOrder.indexOf(selectedCanvas.id)
        const totalCanvasCount = selectedProject.canvasOrder.length
        const nextCanvasIndex = (currentCanvasIndex + 1) % totalCanvasCount
        onSelectCanvas(selectedProject.canvasOrder[nextCanvasIndex])
    }

    return (
        <StyledToolbar css={{ bottom: '144px', left: '12px' }}>
            <ToolbarButton
                size="18px"
                tooltipText="Previous Canvas"
                iconName="chevronUp"
                onClick={handlePreviousClicked}
            />
            <ToolbarButton
                size="18px"
                tooltipText="Next Canvas"
                iconName="chevronDownNew"
                onClick={handleNextClicked}
            />
        </StyledToolbar>
    )
}

const backgroundMap = canvasSource =>
    canvasSource.match({
        Pdf: () => <BackgroundPdfMap canvasSource={canvasSource} className="background-map" />,
        Mapbox: () => <BackgroundMapboxMap canvasSource={canvasSource} className="background-map" />,
        DroneDeploy: () => <BackgroundMapboxMap canvasSource={canvasSource} className="background-map" />,
    })

const StyledCanvasViewContainer = styled('div', {
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateColumns: 'var(--gridLeftColumnWidth) 1fr',
    gridTemplateRows: '1fr',
    gridTemplateAreas: "'navigator map'",
    overflow: 'hidden',
    '.canvas-navigator': {
        gridArea: 'navigator',
        height: 'var(--gridRow1Height)',
    },
})

const StyledMapContainer = styled('div', {
    gridArea: 'map',
    height: 'var(--gridRow1Height)',

    width: 'var(--gridColumn1Width)',
    '.foreground-map': {
        position: 'relative',
    },
    '.background-map': {
        position: 'absolute',
        top: 0,
        left: 0,
        background: '$neutral09',
        backgroundImage: 'url(/grid_background2.svg)',
    },
})

const CanvasSourceSelector = ({ canvasSources, selectedCanvasSource, canvasSourceChanged }) => {
    return (
        <FlexRow
            css={{
                height: '42px',
                border: '1px solid $neutral07',
                background: '$neutral10',
                color: '$neutral04',
                borderRadius: '6px',
                ai: 'center',
                width: 'fit-content',
                overflow: 'clip',
            }}
        >
            <FlexRow
                css={{
                    pl: 10,
                    pr: 4,
                    height: '100%',
                    alignItems: 'center',
                    transitionDuration: '0.4s',
                    '&:hover': {
                        // border: '1px solid $primary04',
                        background: '$primary03',
                    },
                    '&:focus': {
                        // border: '1px solid $primary04',
                        background: '$primary03',
                    },
                }}
            >
                <Icon name="map" iconSize="20" />
                <Select
                    css={{
                        width: 'fit-content',
                        border: 'none',
                        background: 'none',
                        color: '$neutral04',
                        '&:hover': {
                            border: 'none',
                            background: 'none',
                        },
                        '&:enabled': {
                            border: 'none',
                        },
                    }}
                    options={canvasSources}
                    value={selectedCanvasSource.id}
                    onValueChange={canvasSourceChanged}
                />
            </FlexRow>
        </FlexRow>
    )
}

const CanvasView = () => {
    const canvasSourceChanged = canvasSource => dispatch(ReduxActions.selectedCanvasSourceChanged(canvasSource))

    const { dispatch, getState } = useStore()
    const commandHistory = useCommandHistory()
    const navigate = useNavigateWithQuery()
    const selectedCollaboration = useSelector(ReduxSelectors.selectedCollaboration)
    const selectedProject = useSelector(ReduxSelectors.selectedProject)
    const selectedCanvas = useSelector(ReduxSelectors.selectedCanvas)
    const drawingMode = useSelector(ReduxSelectors.drawingMode)
    const selectedTool = useSelector(ReduxSelectors.selectedTool)
    const selectedCanvasSource = useSelector(ReduxSelectors.selectedCanvasSource)
    const canvasSources = useSelector(ReduxSelectors.canvasSourcesForSelectedCanvas)
    const pdfLoadingState = useSelector(ReduxSelectors.pdfLoadingState)
    const backgroundMapLoadingState = useSelector(ReduxSelectors.backgroundMapLoadingState)
    const canCreateCollaboration = useSelector(ReduxSelectors.isCreateAllowed('collaboration', null))
    const { registerCollaborationAsRecentlyViewed } = useRecentCollaborations()

    const selectCanvas = canvasId => {
        dispatch(ReduxActions.geometrySelectionCleared())
        commandHistory.getResources().mapboxDraw?.clearSelection()
        navigate(`../${canvasId}`)
    }

    const isStillLoading = () =>
        !selectedCanvasSource ||
        selectedCanvasSource?.match({
            Pdf: () => pdfLoadingState !== 'loaded',
            Mapbox: () => backgroundMapLoadingState !== 'loaded',
            DroneDeploy: () => backgroundMapLoadingState !== 'loaded',
        })

    // This will be triggered after selectionChange is triggered on the mapbox map,
    // so whenever user selects a pin
    const onSelectionChange = () => {
        const selectedCollaborationId = ReduxSelectors.selectedCollaborationId(getState())
        registerCollaborationAsRecentlyViewed(selectedCollaborationId)
    }

    // HACK: every sample project (and no other) should have this center; see create-organization-handler.js
    const showQRCode = equals(selectedProject?.center, [-122.244338111, 37.543427111])

    const showStreets = window.getStreetMapOpacity() > 0

    return (
        <ProjectPage data-cy="canvas-view" activeTab={ControlBar.TABS.CANVAS}>
            <StyledCanvasViewContainer>
                <NavigatorWrapper selectCanvas={selectCanvas} />
                <StyledMapContainer>
                    {isStillLoading() && (
                        <PageLoading
                            css={{
                                position: 'absolute',
                                zIndex: 300,
                                width: 'calc(100% - 300px)',
                                height: 'calc(100% - 50px)',
                            }}
                        />
                    )}
                    <Map
                        className="foreground-map"
                        showStreets={showStreets}
                        canvasSource={selectedCanvasSource}
                        showTileBoundaries={false}
                    >
                        <MapboxDrawingCanvas
                            mode={drawingMode}
                            selectedTool={selectedTool}
                            onSelectionChange={onSelectionChange}
                        >
                            {/* <MapMarkerController /> */}
                            <MapPopupController />
                            <PhotoMarkerController />
                        </MapboxDrawingCanvas>
                        <TopLeftTools
                            canvasSources={canvasSources}
                            selectedCanvasSource={selectedCanvasSource}
                            showCollaborationTools={canCreateCollaboration}
                            canvasSourceChanged={canvasSourceChanged}
                        />
                        <CanvasSwitchControls
                            onSelectCanvas={selectCanvas}
                            selectedCanvas={selectedCanvas}
                            selectedProject={selectedProject}
                        />
                        <MapLayerZoomControls project={selectedProject} canvasSource={selectedCanvasSource} />
                        {showQRCode && <QrCode text="Get Mobile App" src="/GetMobileAppsQrCode.png" variant="canvas" />}
                        <MapLayerPresence />

                        {selectedCanvasSource && backgroundMap(selectedCanvasSource)}
                        {selectedCollaboration && (
                            <CollaborationWindowContainer>
                                <CollaborationWindow />
                            </CollaborationWindowContainer>
                        )}
                    </Map>
                </StyledMapContainer>
            </StyledCanvasViewContainer>
        </ProjectPage>
    )
}

export default CanvasView
