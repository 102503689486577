// prettier-ignore
import StringTypes from '@range.io/basic-types/src/string-types.js'
import { tagged } from '@range.io/functional'
import PropTypes from 'prop-types'
import { ParticipantShapePropTypes } from './participant-shape.js'

// prettier-ignore
const CommentShape = tagged('CommentShape', {
    id           : StringTypes.Id,
    parentId     : StringTypes.Id,
   
    createdBy    : 'ParticipantShape',
    completedBy  : 'ParticipantShape?', // completed a Note
    completedDate: 'Object?', // Date of completion
    createdAt    : 'Object', // Date
    isNote       : 'Boolean?',
    isPending    : 'Boolean?',
    text         : 'String',
    textIsEdited : 'Boolean?',
})

// prettier-ignore
const CommentShapePropTypes = {
    id           : PropTypes.string,
    parentId     : PropTypes.string,
    
    createdBy    : PropTypes.shape(ParticipantShapePropTypes).isRequired,
    completedBy  : PropTypes.shape(ParticipantShapePropTypes),
    completedDate: PropTypes.object,
    createdAt    : PropTypes.object.isRequired,
    isNote       : PropTypes.bool,
    isPending    : PropTypes.bool,
    text         : PropTypes.string.isRequired,
    textIsEdited : PropTypes.bool,
}

CommentShape.fromComment = shapeLookupTable => comment => {
    const shape = CommentShape.from({
        id: comment.id,
        parentId: comment.parentId,

        createdBy: shapeLookupTable.projectParticipants.get(comment.createdBy),
        completedBy: shapeLookupTable.projectParticipants.get(comment.completedById),
        completedDate: comment.completedDate,
        createdAt: comment.createdAt,
        isNote: comment.isNote,
        isPending: comment.isNote && !comment.completedDate,
        text: comment.text,
        textIsEdited: comment.textIsEdited,
    })

    shape.parent = () => {
        if (comment.parentType === 'Collaboration') return shapeLookupTable.collaborations[comment.parentId]
        if (comment.parentType === 'Feature') return shapeLookupTable.features[comment.parentId]
        if (comment.parentType === 'Upload') return shapeLookupTable.uploads[comment.parentId]

        throw new Error('Comment has no parent')
    }
    return shape
}

export { CommentShape, CommentShapePropTypes }
