/*
 * Comment
 *
 * A user's Comment on a Collaboration, Feature, Upload, UploadCollection, etc.
 
 * TODO add upload and uploadCollection once we HAVE the ability to comment on Uploads
 */
import { mergeRight, tagged } from '@range.io/functional'
import { v4 } from 'uuid'
import { millisOrTimestampToDate } from '../helper/timestamp.js'
import StringTypes from '../string-types.js'

// ---------------------------------------------------------------------------------------------------------------------
// Definitions
// ---------------------------------------------------------------------------------------------------------------------

// prettier-ignore
const Comment = tagged('Comment', {
    id           : StringTypes.Id,
    createdAt    : 'Object', // Date
    parentId     : StringTypes.Id,
    parentType   : StringTypes.CommentParentType,
    createdBy    : StringTypes.Id,
    text         : 'String',
    textIsEdited : 'Boolean',
    isNote       : 'Boolean',
    
    // notes only
    completedById: StringTypes.OptionalId,
    completedDate: 'Object?', // Date
    
    collaborationId: StringTypes.OptionalId,
})

// TODO: remove Comment renames
Comment.prototype.renameFieldButLoudly('parent', 'parentId')
Comment.prototype.renameFieldButLoudly('completedBy', 'completedById')

/*
 * Create a Comment for an existing Collaboration
 * @sig commentForCollaboration :: (Collaboration, Id, String) -> Comment
 */
Comment.commentForCollaboration = (collaboration, userId, text) =>
    // prettier-ignore
    Comment.from({
        id          : v4(),
        createdAt   : new Date(),
        parentId    : collaboration.id,
        parentType  : 'Collaboration',
        createdBy   : userId,
        text,
        
        textIsEdited: false, // new Comment is not yet edited
        isNote      : false, // new Comment doesn't start as a Note
        // completedBy, completedDate don't start off being set
    })

/*
 * Create a Comment for an existing Collaboration
 * @sig commentForCollaboration :: (Collaboration, Id, String) -> Comment
 */
Comment.noteForCollaboration = (collaboration, userId, text) =>
    // prettier-ignore
    Comment.from({
        id          : v4(),
        createdAt   : new Date(),
        parentId    : collaboration.id,
        parentType  : 'Collaboration',
        createdBy   : userId,
        text,

        textIsEdited: false, // new Comment is not yet edited
        isNote      : true, // new Comment doesn't start as a Note
    })

/*
 * Create a Comment for an existing Upload
 * @sig commentForUpload :: (Upload, Id, String) -> Comment
 */
Comment.commentForUpload = (upload, userId, text) =>
    // prettier-ignore
    Comment.from({
        id          : v4(),
        createdAt   : new Date(),
        parentId    : upload.id,
        parentType  : 'Upload',
        createdBy   : userId,
        text,

        textIsEdited: false, // new Comment is not yet edited
        isNote      : false, // Uploads don't have notes
        // completedBy, completedDate can't be set for an Upload, only a Collaboration
    })

// ---------------------------------------------------------------------------------------------------------------------
// Serialization
// ---------------------------------------------------------------------------------------------------------------------

/*
 * @sig fromFirebase = {k:v} -> Comment
 */
Comment.fromFirebase = o =>
    // prettier-ignore
    Comment.from({
        id             : o.id,
        createdAt      : millisOrTimestampToDate(o.createdAt),
        parentId       : o.parent,
        parentType     : o.parentType,
        createdBy      : o.createdBy,
        text           : o.text,
        textIsEdited   : o.textIsEdited,
        isNote         : o.isNote,
        completedById  : o.completedBy,
        completedDate  : millisOrTimestampToDate(o.completedTimestamp),
        
        collaborationId: o.collaborationId,
    })

// ---------------------------------------------------------------------------------------------------------------------
// Syntactic sugar for creating a specific kind of Comment object
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Create a new Comment merging an old one with values from changes
 * @sig update :: (Comment, {k:v}) -> Comment
 */
Comment.update = (comment, changes) => Comment.from(mergeRight(comment, changes))

export default Comment
