import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { CheckIcon } from '@radix-ui/react-icons'
import { TimeFormat } from '@range.io/basic-types'
import { mergeRight } from '@range.io/functional'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Avatar, Box, FlexColumn, FlexRow, Text, Tooltip } from '../components-reusable/index.js'
import PossiblySuspendedUserName from '../components-reusable/PossiblySuspendedUserName.js'
import { styled } from '../range-theme/index.js'
import CommentBox from './CommentBox.js'
import { CommentMoreActionsDropdown } from './CommentFeedItem.js'

const StyledMainContainer = styled(FlexColumn, {
    ai: 'start',
    gap: '8px',
    mb: '12px',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    minWidth: '0',
    display: 'flex',
    flexDirection: 'row',
})

const StyledNoteContainer = styled(FlexRow, {
    minHeight: '32px',
    ai: 'stretch',
    transitionDuration: '0.4s',
    fontSize: '14px',
    width: '100%',
    br: 6,
    border: '1px solid $neutral07',
    background: '$neutral08',
    ml: '40px',
})

const StyledIndent = styled(Box, {
    width: '5px',
    height: 'auto',
    br: '8px 0px 0px 8px',
    backgroundColor: '$orange03',

    variants: {
        isCompleted: {
            true: {
                backgroundColor: '$green03',
            },
        },
    },
})

const StyledTopSectionContainer = styled(FlexRow, {
    height: '39px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
})

const StyledUserNameTimeStamp = styled(Text, {
    display: 'flex',
    flexDirection: 'column',
    gap: '0px',
    width: '100%',
})

const StyledUploadDateText = styled(Text, {
    color: '$neutral05',
    fs: '12px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
})

const StyledCommentContent = styled(Text, {
    color: '$neutral04',
    fontSize: 14,
    width: '100%',
    marginRight: '10px',
    whiteSpace: 'pre-line',
})

const StyledCheckbox = styled(CheckboxPrimitive.Root, {
    all: 'unset',
    backgroundColor: '$neutral10',
    width: 24,
    height: 24,
    borderRadius: 999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid $neutral07',
    cursor: 'pointer',
    transitionDuration: '0.4s',
    '&:hover': { backgroundColor: '$neutral09' },
    '&[data-state="checked"]': { backgroundColor: '$green03' },
})

const StyledCheckboxIndicator = styled(CheckboxPrimitive.Indicator, {
    width: '16px',
    m: 'auto',
    display: 'flex',

    '&[data-state="checked"]': {
        color: 'white',
    },
})

// CollaborationWindowNote
export const CollaborationWindowNote = ({
    commentShape,
    simulateEditing = false,
    css = {},
    isEditingAllowed,

    onNoteCompleted,
    onNoteChanged,
    onNoteDeleted,
}) => {
    const { createdAt, createdBy, completedBy, text: noteText, completedDate } = commentShape

    const [isEditing, setIsEditing] = useState(simulateEditing)
    const onEdit = setIsEditing
    const onCancelEdit = () => setIsEditing(false)
    const onSaveEditChanges = value => {
        setIsEditing(false)
        onNoteChanged(value)
    }

    css = mergeRight({ zIndex: 2, position: 'relative' }, css)

    const tooltipText = completedBy ? 'Mark incomplete' : 'Resolve note'
    const border = completedBy ? '1px solid $green04' : '1px solid $orange03'

    const userNameTextCss = {
        color: '$neutral04',
        fs: '14px',
        fw: 700,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    }

    return (
        <StyledMainContainer css={css}>
            <StyledTopSectionContainer>
                <Avatar size="32" css={{ marginRight: '10px' }} participantShape={createdBy} />
                <StyledUserNameTimeStamp>
                    <PossiblySuspendedUserName css={userNameTextCss} participantShape={createdBy} />
                    <StyledUploadDateText>{TimeFormat.primary(createdAt)}</StyledUploadDateText>
                </StyledUserNameTimeStamp>
                <CommentMoreActionsDropdown
                    css={{ ml: 'auto' }}
                    allowed={isEditingAllowed}
                    onDelete={onNoteDeleted}
                    onEdit={onEdit}
                />
            </StyledTopSectionContainer>
            {isEditing && (
                <CommentBox.Edit
                    css={{ w: '100%', ml: '40px' }}
                    initialText={noteText}
                    onSave={onSaveEditChanges}
                    onCancel={onCancelEdit}
                />
            )}
            {!isEditing && (
                <StyledNoteContainer>
                    <StyledIndent isCompleted={!!completedBy} />
                    <FlexRow css={{ ai: 'center', width: '100%', padding: '10px', gap: 8 }}>
                        <StyledCommentContent>{noteText}</StyledCommentContent>
                        <Tooltip tooltipText={tooltipText} side="right" align="center">
                            <FlexRow>
                                <StyledCheckbox
                                    css={{ '&:hover': { border } }}
                                    checked={!!completedBy}
                                    onCheckedChange={onNoteCompleted}
                                >
                                    <StyledCheckboxIndicator>
                                        <CheckIcon />
                                    </StyledCheckboxIndicator>
                                </StyledCheckbox>
                            </FlexRow>
                        </Tooltip>
                    </FlexRow>
                </StyledNoteContainer>
            )}
            {completedDate && (
                <FlexRow css={{ gap: '2px', ml: '40px' }}>
                    <PossiblySuspendedUserName
                        css={{ fs: '12px', fw: 700, color: '$neutral05' }}
                        participantShape={completedBy}
                    />
                    <Text css={{ fs: '12px', fw: 500, color: '$neutral05' }}>
                        &nbsp;completed this note {TimeFormat.tertiary(completedDate)}
                    </Text>
                </FlexRow>
            )}
        </StyledMainContainer>
    )
}

CollaborationWindowNote.propTypes = {
    commentShape: PropTypes.object.isRequired,
    simulateEditing: PropTypes.bool,
    css: PropTypes.object,
    isEditingAllowed: PropTypes.bool.isRequired,

    onNoteCompleted: PropTypes.func.isRequired,
    onNoteChanged: PropTypes.func.isRequired,
    onNoteDeleted: PropTypes.func.isRequired,
}

export default CollaborationWindowNote
