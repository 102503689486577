/*
 * Shape cache -- updated for speed
 *
 * There is a singleton containing the shapes along with a previousState; when a new state arrives, any
 * elements added, removed or changed from the previousState and converted to Shapes
 * and the new state becomes the previousState
 *
 * Because most of the time, one element arrives at a time, there is special handling to simply insert
 * a single item rather than needlessly re-sorting
 */

import LookupTable from '@range.io/functional/src/lookup-table.js'
import { CanvasShape } from '../react-shapes/canvas-shape.js'
import { CanvasSourceShape } from '../react-shapes/canvas-source-shape.js'
import { CollaborationShape } from '../react-shapes/collaboration-shape.js'
import { CommentShape } from '../react-shapes/comment-shape.js'
import { FeatureShape } from '../react-shapes/feature-shape.js'
import { GeometryShape } from '../react-shapes/geometry-shape.js'
import { ParticipantShape } from '../react-shapes/participant-shape.js'
import { StatusShape } from '../react-shapes/status-shape.js'
import { TagShape } from '../react-shapes/tag-shape.js'
import { UpdateShape } from '../react-shapes/update-shape.js'
import { UploadShape } from '../react-shapes/upload-shape.js'
import { ReduxSelectors } from './index.js'

// if any of these keys in the state has changed, we probably need to update some subset of the shapes
const keysToCheckForChanges = [
    'canvases',
    'canvasSources',
    'collaborations',
    'comments',
    'features',
    'geometries',
    'organizations',
    'projects',
    'statusNames',
    'tagNames',
    'updates',
    'uploads',
    'users',

    'selectedOrganizationId',
    'selectedProjectId',
]

// Singleton caches
let previousState = {}
const shapes = {
    canvasSources: LookupTable([]),
    canvases: LookupTable([]),
    collaborations: LookupTable([]),
    comments: LookupTable([]),
    features: LookupTable([]),
    geometries: LookupTable([]),
    statuses: LookupTable([]),
    tags: LookupTable([]),
    updates: LookupTable([]),
    uploads: LookupTable([]),

    projectParticipants: LookupTable([]),
}

/*
 * Shallow diff of two objects (eg. previousState.updates and state.updates); faster and simpler than diffObjects
 * @sig shallowDiffObjects :: ({k:v}, {k:v}) -> { added: [String], changed: [String], unchanged: [String] }
 */
const shallowDiffObjects = (a, b) => {
    const aKeys = Object.keys(a)
    const bKeys = Object.keys(b)

    return {
        added: bKeys.filter(key => !(key in a)),
        changed: aKeys.filter(key => key in b && a[key] !== b[key]),
        unchanged: aKeys.filter(key => key in b && a[key] === b[key]),
    }
}

/*
 * Binary search the array using the comparator to compare item to the value at the current index.
 * a must already be sorted using comparator
 *
 * @sig findInsertionIndex :: (Comparator, [A], A) -> Number
 *  Comparator :: (A, A) -> -1|0|1
 */
const findInsertionIndex = (comparator, a, item) => {
    let low = 0
    let high = a.length

    while (low < high) {
        const mid = Math.floor((low + high) / 2)
        if (comparator(a[mid], item) < 0) low = mid + 1
        else high = mid
    }

    return low
}

/*
 * Faster -- for a single item -- than adding item to a and sorting
 * @sig insertInSortedOrder :: (Comparator, [A], A) -> [A]
 *  Comparator :: (A, A) -> -1|0|1
 */
const insertInSortedOrder = (comparator, a, item) => {
    a.splice(findInsertionIndex(comparator, a, item), 0, item)
    return a
}

/*
 * Create a new LookupTable for old, new and changed items, possibly sorting using comparator
 * @sig updateShapeLookupTable :: ([A], [A], [A], Comparator) -> [A]
 */
const updateShapeLookupTable = (unchangedItems, changedItems, addedItems, comparator) => {
    const toInsert = changedItems.concat(addedItems)
    const singleInsert = toInsert.length === 1

    if (!comparator) return LookupTable(unchangedItems.concat(toInsert))
    if (comparator && singleInsert) return LookupTable(insertInSortedOrder(comparator, unchangedItems, toInsert[0]))

    // multiple items to insert; sort
    const items = unchangedItems.concat(toInsert)
    items.sort(comparator)
    return LookupTable(items)
}

/*
 * Compute the unchanged, added and changed values from the previousState to the new State
 * Then create new Shapes for the added and changed items and create a new LookupTable from the unchanged and new shapes
 * @sig _recomputeShapes :: (State, ShapeFunc, String, {k:v}, {k:v}, Comparator) -> LookupTable
 *  ShapeFunc = Item -> ItemShape
 */
const _recomputeShapes = (
    state,
    shapeFromItem,
    shapeKey,
    oldSubstate,
    newSubstate,
    comparator,
    forceRecompute = false
) => {
    const _shapeFromItem = id => shapeFromItem(shapes, state)(newSubstate[id])
    const anyCollaborationChildHasChanged = () =>
        previousState.comments !== state.comments ||
        previousState.updates !== state.updates ||
        previousState.uploads !== state.uploads

    const oldLookupTable = shapes[shapeKey]

    let { added, changed, unchanged } = shallowDiffObjects(oldSubstate, newSubstate)

    // if ANY child of ANY Collaboration has changed, recompute ALL the existing CollaborationShapes
    // or if forceRecompute flag is set to true
    if ((shapeKey === 'collaborations' && anyCollaborationChildHasChanged()) || forceRecompute) {
        changed = changed.concat(unchanged)
        unchanged = []
    }

    const unchangedShapes = unchanged.map(id => oldLookupTable.get(id))
    const addedShapes = added.map(_shapeFromItem)
    const changedShapes = changed.map(_shapeFromItem)

    // combine the existing, sorted, unchanged shapes and new shapes for the added and changed items into a LookupTable
    shapes[shapeKey] = updateShapeLookupTable(unchangedShapes, changedShapes, addedShapes, comparator)
}

/*
 * Update a LookupTable in-place
 * @sig recomputeShapes :: (State, ShapeFunc, String, String, Comparator) -> () (and update the LookupTable)
 */
const recomputeShapes = (state, shapeFromItem, stateKey, shapeKey = stateKey, comparator, forceRecompute) => {
    const oldSubstate = previousState[stateKey] || {}
    const newSubstate = state[stateKey] || {}
    _recomputeShapes(state, shapeFromItem, shapeKey, oldSubstate, newSubstate, comparator, forceRecompute)
}

/*
 * Update the special-case LookupTable for projectParticipants, which is different from all the other keys of Shapes,
 * because the participants don't come from the root of state.participants, but from state.selectedProject.participants
 * @sig recomputeProjectParticipantShapes :: (State) -> () (and update the LookupTable)
 */
const recomputeProjectParticipantShapes = state => {
    const oldProject = previousState.projects && ReduxSelectors.selectedProject(previousState)
    const newProject = state.projects && ReduxSelectors.selectedProject(state)
    const oldSubstate = oldProject?.participants || {}
    const newSubstate = newProject?.participants || {}

    _recomputeShapes(state, ParticipantShape.fromParticipant, 'projectParticipants', oldSubstate, newSubstate)
}

// don't recompute a set of shapes if the source items are identical to the previous ones
const possiblyRecomputeShapes = (state, shapeFromItem, stateKey, shapeKey = stateKey, comparator = undefined) => {
    if (previousState[stateKey] === state[stateKey]) return
    recomputeShapes(state, shapeFromItem, stateKey, shapeKey, comparator, false)
}

// Special case of possiblyRecomputeShapesfor projectParticipants
const possiblyRecomputeProjectParticipants = state => {
    const oldProject = previousState.projects && ReduxSelectors.selectedProject(previousState)
    const newProject = state.projects && ReduxSelectors.selectedProject(state)

    if (previousState.projects === state.projects && oldProject === newProject) return

    recomputeProjectParticipantShapes(state)
}

// sort
const byName = (a, b) => a.name.localeCompare(b.name)
const byDate = (a, b) => a.createdAt - b.createdAt

/*
 * Update (that is, generate in-place) new Shapes for the new state.
 * Each substate of the state is compared to the corresponding substate of the previousState
 * and any required changes to Shapes are computed from the differences
 * Impure: operates on (and returns) a singleton (shapes) that is updated in-place
 * @sit generateCollaborationShapes :: State => {k:LookupTable<Shape>}
 * j
 */
const generateCollaborationShapes = state => {
    // if NOTHING has changed, just return the previous shapes
    const noChanges = keysToCheckForChanges.every(name => state[name] === previousState[name])
    if (noChanges) return shapes

    possiblyRecomputeProjectParticipants(state)
    possiblyRecomputeShapes(state, StatusShape.fromStatusName, 'statusNames', 'statuses')
    recomputeShapes(state, TagShape.fromTagName, 'tagNames', 'tags', byName, true) // always force recompute as collaborations tags might have changed
    possiblyRecomputeShapes(state, CanvasSourceShape.fromCanvasSource, 'canvasSources')
    possiblyRecomputeShapes(state, CanvasShape.fromCanvas, 'canvases')
    possiblyRecomputeShapes(state, GeometryShape.fromGeometry, 'geometries')
    possiblyRecomputeShapes(state, FeatureShape.fromFeature, 'features')
    possiblyRecomputeShapes(state, UploadShape.fromUpload, 'uploads', 'uploads', byDate)
    possiblyRecomputeShapes(state, CommentShape.fromComment, 'comments', 'comments', byDate)
    possiblyRecomputeShapes(state, UpdateShape.fromUpdate, 'updates', 'updates', byDate)
    recomputeShapes(state, CollaborationShape.fromCollaboration, 'collaborations') // because a child may have changed

    previousState = state

    return shapes
}

export { generateCollaborationShapes }
