import { Invitation } from '@range.io/basic-types'
import { pluck, taggedSum } from '@range.io/functional'
import { ReduxActions, ReduxSelectors } from '../../redux/index.js'
import * as Segment from '../../segment/segment.js'
import CommandPlayer, { simpleInboundAddedRunCommand } from './command-player.js'
import { inviteToOrganization } from './https-calls.js'
import verifyAndUpdateSeatsCount from './helpers/verify-and-update-seats-count.js'

// ---------------------------------------------------------------------------------------------------------------------
// InvitationAddedCommand
// ---------------------------------------------------------------------------------------------------------------------
const InvitationAddedCommand = taggedSum('InvitationAddedCommand', {
    Outbound: { invitations: '[Invitation]' }, // MULTIPLE invitations can be sent at once to Firebase
    Inbound: { invitation: 'Invitation' }, // ...but they come back in one-at-a-time
})

// ---------------------------------------------------------------------------------------------------------------------
// Handle commands
// ---------------------------------------------------------------------------------------------------------------------
const getItem = c => c.invitation

/*
 * A InvitationAddedCommand.Inbound has arrived from Firestore; send it to redux
 * (since the WHOLE document is returned, we want to add and not change the Invitation)
 */
const runInboundCommand = (resources, command) =>
    simpleInboundAddedRunCommand(resources, command, Invitation, ReduxActions.invitationAdded, getItem)

/*
 * A InvitationsAddedCommand.Outbound has arrived from the UI; send it to redux AND Firestore
 */
const runOutboundCommand = async (resources, command) => {
    const { invitations } = command
    const { dispatch, getState, organizationId, displayError } = resources

    if (invitations.length === 0) return

    try {
        const isOrgOnFreePlan = ReduxSelectors.isFreePlanOrganization(getState(), organizationId)

        const inviteOne = invitation => {
            const params = ReduxSelectors.paramsForTrackEvent(getState())
            Segment.sendTrack('invite sent', invitation.id, params)

            return inviteToOrganization(organizationId, invitation)
        }

        // If we add Collaborators or Admins, check if we have enough seats and update if more needed
        if (!isOrgOnFreePlan && invitations[0].organizationRole !== 'Guest') {
            verifyAndUpdateSeatsCount(getState(), invitations.length)
        }

        const promises = invitations.map(inviteOne)
        await Promise.all(promises)

        const toastLabel = 'Invitations sent to ' + pluck('inviteeEmail', invitations).join(' ')
        const toast = { id: invitations[0].id, severity: 'success', toastLabel, showUndo: false }
        dispatch(ReduxActions.toastAdded(toast))
    } catch (e) {
        displayError(e)
        throw e
    }
}

const addCommand = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        InvitationAddedCommand,
        CommandPlayer({
            CommandType: InvitationAddedCommand,
            Type: Invitation,
            collectionPath: organizationId => `/organizations/${organizationId}/invitations/`,
            runInboundCommand,
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'added',
            resourceKey: 'organizationId',
        })
    )
}

export { InvitationAddedCommand, addCommand }
