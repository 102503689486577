import { TimeFormat } from '@range.io/basic-types'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import {
    Avatar,
    Button,
    DropdownMenuWithTooltip,
    FlexColumn,
    FlexRow,
    Icon,
    RichText,
    Text,
} from '../components-reusable/index.js'
import PossiblySuspendedUserName from '../components-reusable/PossiblySuspendedUserName.js'
import CommentBox from './CommentBox.js'

const CommentMoreActionsDropdown = ({ allowed, onDelete, onEdit }) =>
    allowed ? (
        <DropdownMenuWithTooltip.Root>
            <DropdownMenuWithTooltip.Trigger tooltipText="More Actions">
                <Button css={{ w: 32, color: '$neutral05' }} variant="dropdown-trigger">
                    <Icon iconSize="28px" name="threeDot" />
                </Button>
            </DropdownMenuWithTooltip.Trigger>
            <DropdownMenuWithTooltip.Content side="bottom" align="end" sideOffset={6}>
                <DropdownMenuWithTooltip.MenuItem>
                    <Button variant="dropdown-menuitem" onClick={onEdit}>
                        <Icon iconSize="16px" name="edit" css={{ mr: 8 }} />
                        <Text>Edit</Text>
                    </Button>
                </DropdownMenuWithTooltip.MenuItem>
                <DropdownMenuWithTooltip.MenuItem>
                    <Button variant="dropdown-menuitem-destructive" onClick={onDelete}>
                        <Icon iconSize="16px" name="trash" css={{ mr: 8 }} />
                        <Text>Delete</Text>
                    </Button>
                </DropdownMenuWithTooltip.MenuItem>
            </DropdownMenuWithTooltip.Content>
        </DropdownMenuWithTooltip.Root>
    ) : null

/*
 * Displays a single comment with a header about it's author and date of creation
 */
const CommentFeedItem = ({ createdAt, onDelete, onSave, text, textIsEdited, participantShape, isEditingAllowed }) => {
    const [isEditing, setIsEditing] = useState(false)
    const handleSave = newValue => {
        onSave(newValue)
        setIsEditing(false)
    }
    const handleEditCancel = () => setIsEditing(false)
    const handleEditAction = () => setIsEditing(true)

    return (
        <FlexColumn data-cy="comment-added" css={{ gap: '10px', mb: '12px' }}>
            <FlexRow css={{ ai: 'center' }}>
                <Avatar size="32" participantShape={participantShape} css={{ marginRight: '8px', zIndex: 1 }} />
                <FlexColumn css={{ gap: '2px', width: '100%' }}>
                    <PossiblySuspendedUserName
                        css={{ color: '$neutral04', fontSize: '14px', fw: 700 }}
                        participantShape={participantShape}
                    />
                    <Text css={{ color: '$neutral05', fontSize: '12px' }}>{TimeFormat.primary(createdAt)}</Text>
                </FlexColumn>
                <CommentMoreActionsDropdown allowed={isEditingAllowed} onDelete={onDelete} onEdit={handleEditAction} />
            </FlexRow>
            {isEditing ? (
                <CommentBox.Edit
                    css={{ ml: '40px' }}
                    initialText={text}
                    onSave={handleSave}
                    onCancel={handleEditCancel}
                />
            ) : (
                <FlexRow css={{ ml: '40px' }}>
                    <RichText css={{ whiteSpace: 'pre-line' }} text={text} />
                    {textIsEdited && <RichText text="&nbsp;(edited)" />}
                </FlexRow>
            )}
        </FlexColumn>
    )
}

CommentFeedItem.propTypes = {
    createdAt: PropTypes.object.isRequired,
    text: PropTypes.string.isRequired,
    textIsEdited: PropTypes.bool,
    onDelete: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    participantShape: PropTypes.object.isRequired,
}

export { CommentMoreActionsDropdown }
export default CommentFeedItem
