import { formatDistance } from 'date-fns'
import React from 'react'
import { useSelector } from 'react-redux'
import { FlexRow, Icon, Text } from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'
import { ReduxSelectors } from '../redux/index.js'
import { visitCustomerPortal } from '../firebase/commands/https-calls.js'

const StyledInfoBanner = styled(FlexRow, {
    width: '100%',
    height: '36px',
    background: 'linear-gradient(90deg, #FF7A00, #994900)',
    position: 'fixed',
    zIndex: 999,
    alignItems: 'center',
    justifyContent: 'center',
})

const StyledText = styled(Text, {
    fs: 14,
    fw: 500,
    color: '$white',
})

const IconCircle = styled('span', {
    width: '18px',
    height: '18px',
    borderRadius: '999px',
    backgroundColor: '#321800',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
})

const StyledLink = styled('span', {
    textDecoration: 'underline',
    cursor: 'pointer',
})

const getBillingErrorText = (billingError, isEditingOrganizationAllowed, onUpdatePaymentClick) => {
    let errorText

    const { priority, gracePeriodEndsTimestamp } = billingError

    const timeLeft = formatDistance(gracePeriodEndsTimestamp, new Date())

    switch (priority) {
        case 1: {
            errorText = isEditingOrganizationAllowed ? (
                <span>
                    Your Range account has a billing issue. Please{' '}
                    <StyledLink onClick={onUpdatePaymentClick}>update the payment details</StyledLink> to maintain
                    access to your team’s data.
                </span>
            ) : (
                'Your Range account has a billing issue. Have an Admin update the payment details to maintain access to your team’s data.'
            )
            break
        }
        default:
            errorText = `Something went wrong with your subscription. Plase contact us to maintain access to your team’s
    data.`
    }

    return (
        <span>
            {errorText} ({timeLeft} remaining)
        </span>
    )
}

const BillingErrorInfo = ({ billingError, onUpdatePaymentClick }) => {
    if (!billingError) return null
    const isEditingOrganizationAllowed = useSelector(ReduxSelectors.isUpdateAllowed('organization'))

    const text = getBillingErrorText(billingError, isEditingOrganizationAllowed, onUpdatePaymentClick)

    return (
        <FlexRow
            css={{
                alignItems: 'center',
                gap: '4px',
            }}
        >
            <IconCircle>
                <Icon name="warning" iconSize="14px" css={{ color: '$orange03' }} />
            </IconCircle>
            <StyledText>{text}</StyledText>
        </FlexRow>
    )
}

const BillingError = () => {
    const selectedOrganization = useSelector(ReduxSelectors.selectedOrganization)

    if (!selectedOrganization.billingError?.gracePeriodEndsTimestamp) return null

    const _visitCustomerPortal = async () => {
        const { url } = await visitCustomerPortal(selectedOrganization.id)
        window.location.href = url
    }

    const { gracePeriodEndsTimestamp } = selectedOrganization.billingError

    // do not render the billing error banner if we are over gracePeriodEndsTimestamp
    // as the app will be blocked when that happens
    if (gracePeriodEndsTimestamp < new Date()) return null

    return (
        <StyledInfoBanner>
            <BillingErrorInfo
                billingError={selectedOrganization.billingError}
                onUpdatePaymentClick={_visitCustomerPortal}
            />
        </StyledInfoBanner>
    )
}

export { getBillingErrorText }
export default BillingError
