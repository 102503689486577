import StringTypes from '@range.io/basic-types/src/string-types.js'
import { tagged } from '@range.io/functional'
import PropTypes from 'prop-types'
import { ParticipantShape } from './participant-shape.js'

// prettier-ignore
const UpdateShape = tagged('UpdateShape', {
    id        : StringTypes.Id,
    parentId  : StringTypes.Id,

    createdAt : 'Object', // Date
    field     : 'String',
    newValue  : 'Any?',// String|Int|Id
    oldValue  : 'Any?', // String|Int|Id
    createdBy : 'ParticipantShape',
    assignee  : 'ParticipantShape?' // only present when the field is 'assignee'
})

// prettier-ignore
const UpdateShapePropTypes = {
    id        : PropTypes.string.isRequired,
    parentId  : PropTypes.string.isRequired,

    createdAt : PropTypes.object.isRequired,
    field     : PropTypes.string.isRequired,
    newValue  : PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    oldValue  : PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    createdBy : PropTypes.shape(ParticipantShape).isRequired,
    assignee  : PropTypes.shape(ParticipantShape).isRequired,
}

UpdateShape.fromUpdate = shapeLookupTable => update => {
    const shape = UpdateShape.from({
        id: update.id,
        parentId: update.parentId,

        createdAt: update.createdAt,
        createdBy: shapeLookupTable.projectParticipants.get(update.createdBy),
        field: update.field,
        newValue: update.newValue,
        oldValue: update.oldValue,

        assignee: update.field === 'assignee' ? shapeLookupTable.projectParticipants.get(update.newValue) : undefined,
    })

    shape.parent = () => shapeLookupTable.collaborations.get(update.parent)
    return shape
}

export { UpdateShape, UpdateShapePropTypes }
