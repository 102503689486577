import { Collaboration, Geometry, Participant } from '@range.io/basic-types'
import { pluck, sort, uniq } from '@range.io/functional'
import { pdf } from '@react-pdf/renderer'
import { format } from 'date-fns'
import React from 'react'
import { useSelector, useStore } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getImageUrl, URL_SEARCH_ORDER } from '../../components-reusable/hooks/useImageUrl'
import { ReduxSelectors } from '../../redux'
import { getImageUri, makeCancelable } from './helpers'
import TasksReportPdfDoc from './TasksReportPdfDoc'

const useTaskListReport = () => {
    const { getState } = useStore()

    const allCollaborations = useSelector(ReduxSelectors.collaborationLookupTable)
    const allCanvases = useSelector(ReduxSelectors.canvasLookupTable)
    const allTagNames = useSelector(ReduxSelectors.tagNameLookupTable)
    const selectedProject = useSelector(ReduxSelectors.selectedProject)
    const selectedOrganization = useSelector(ReduxSelectors.selectedOrganization)
    const allStatusNames = useSelector(ReduxSelectors.statusNames)
    const projectIdentifier = selectedProject.identifier

    const { projectId } = useParams()

    const getUserFromParticipants = userId => selectedOrganization.participants[userId]
    const getTagNames = tagIds => tagIds.map(tag => allTagNames[tag].name)

    const prepareReport = selectedTasksIds => {
        const reportPromise = async () => {
            const tasks = selectedTasksIds.map(taskId => allCollaborations[taskId])

            const tasksData = await Promise.all(
                tasks.map(async task => {
                    const author = getUserFromParticipants(task.createdBy)
                    const state = getState()
                    const geometry = ReduxSelectors.geometryForCollaboration(state, task)
                    const uploads = ReduxSelectors.uploadsForCollaboration(state, task)
                    const updates = ReduxSelectors.updatesForCollaboration(state, task.id)
                    const comments = ReduxSelectors.commentsForCollaboration(state, task)
                    const authorAvatarUrl = await getImageUri(author.avatarUrl)

                    const canvasSource = Object.values(state.canvasSources).filter(
                        cs => cs.canvasId === geometry.canvasId
                    )[0]

                    let geometryDataFormatted
                    if (
                        canvasSource?.isGeolocated ||
                        canvasSource?.type === 'mapbox' ||
                        canvasSource?.type === 'dronedeploy'
                    )
                        geometryDataFormatted = Geometry.getFormattedForDisplay(geometry)

                    const enhancedComments = comments.map(comment => {
                        const author = getUserFromParticipants(comment.createdBy)
                        return {
                            ...comment,
                            authorName: Participant.fullName(author),
                            authorAvatarUrl,
                        }
                    })

                    let completedDate = null
                    const completedBy = {
                        avatarUrl: null,
                        userFullName: null,
                    }

                    if (updates.length) {
                        const newestStatusUpdate = sort(
                            (a, b) => b.createdAt - a.createdAt,
                            updates.filter(update => update.field === 'statusName')
                        )?.[0]

                        // get the date only, when status was updated to completed
                        if (
                            newestStatusUpdate &&
                            newestStatusUpdate.newValue === Collaboration.completedCollaborationStatus
                        ) {
                            completedDate = newestStatusUpdate.createdAt
                            const completedByUser = getUserFromParticipants(newestStatusUpdate.userId)
                            completedBy.avatarUrl = await getImageUri(completedByUser.avatarUrl)
                            completedBy.userFullName = Participant.fullName(completedByUser)
                        }
                    }

                    const uploadsTags = uploads
                        .map(upload => upload.tagIds)
                        .filter(tags => tags)
                        .flat()

                    const uploadsData = await Promise.all(
                        uploads.map(async ({ id, fileType, name, fileSize, storageVersion }) => {
                            const thumbnailUrl = await getImageUrl(
                                projectId,
                                id,
                                URL_SEARCH_ORDER.ANNOTATED_THUMBNAIL_1,
                                storageVersion
                            )
                            return {
                                thumbnailUrl,
                                fileType,
                                name,
                                fileSize,
                            }
                        })
                    )

                    const locationSnapshotUrl = await getImageUrl(
                        projectId,
                        task.id,
                        URL_SEARCH_ORDER.LOCATION,
                        task.storageVersion
                    )
                    const canvas = allCanvases[geometry.canvasId]

                    const isCompleted = allStatusNames[task.statusName].isCompleted
                    const isOverdue = task.dueDate < new Date() && !isCompleted
                    const assignee =
                        task.assignee && ReduxSelectors.selectedProjectParticipantWithId(getState(), task.assignee)
                    if (assignee && assignee.avatarUrl) assignee.avatarUrl = await getImageUri(assignee?.avatarUrl)
                    const avatarUrl = await getImageUri(author.avatarUrl)

                    return {
                        canvas,
                        date: task.createdAt,
                        description: task.description,
                        locationLabel: canvas.name,
                        locationSnapshotUrl,
                        name: task.name,
                        tagNames: getTagNames(uniq(uploadsTags.concat(task.tags))),
                        userAvatarUrl: avatarUrl,
                        userName: Participant.fullName(author),
                        dueDate: task.dueDate,
                        isOverdue,
                        statusName: task.statusName,
                        completedDate,
                        completedBy,
                        uploadsData,
                        assignee,
                        comments: enhancedComments,
                        projectIdentifier,
                        identifier: task.identifier,
                        geometryData: geometryDataFormatted,
                    }
                })
            )

            const sortedByDate = tasks.sort((a, b) => a.createdAt - b.createdAt)
            const dateFormat = 'MMM d, y'
            const fromDate = format(sortedByDate[0].createdAt, dateFormat)
            const toDate = format(sortedByDate[sortedByDate.length - 1].createdAt, dateFormat)
            const dateRangeString = `${fromDate} - ${toDate}`

            const assignees = uniq(
                tasksData
                    .filter(task => task.assignee)
                    .map(task => {
                        return {
                            ...task.assignee,
                            tasksCount: tasks.filter(item => item.assignee === task.assignee.id).length,
                        }
                    })
            )

            const categories = uniq(
                tasksData
                    .filter(task => task.statusName)
                    .map(task => {
                        return {
                            status: allStatusNames[task.statusName],
                            tasksCount: tasks.filter(item => item.statusName === task.statusName).length,
                        }
                    })
            )

            const tags = uniq(pluck('tagNames', tasksData).flat())

            const logo = await getImageUri(selectedOrganization.logo)

            const data = {
                organizationName: selectedOrganization.name,
                logo,
                projectName: selectedProject.name,
                tasksData,
                tasksCount: tasksData.length,
                overdueCount: tasksData.filter(task => task.isOverdue).length,
                assignees,
                dateRangeString,
                categories,
                tags,
            }

            const doc = <TasksReportPdfDoc {...data} />
            const blob = await pdf(doc).toBlob()
            return blob
        }
        return makeCancelable(reportPromise())
    }

    const openReport = (reportBlob = null) => {
        if (!reportBlob) throw new Error('Requesting openReport on an empty report data blob!')
        const url = URL.createObjectURL(reportBlob)
        window.open(url, '_blank')
    }

    return { prepareReport, openReport }
}

export default useTaskListReport
