import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { formatDistance } from 'date-fns'
import { ReduxActions, ReduxSelectors } from '../redux/index.js'
import { Box, Button, Checkbox, FlexColumn, FlexRow, Icon, Text } from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'
import { visitCustomerPortal } from '../firebase/commands/https-calls.js'

const StyledOuterBox = styled(Box, {
    bg: '$neutral09',
    color: '$neutral04',
    width: '500px',
    boxSizing: 'content-box',
    border: '1px solid $neutral07',
    br: '6px',
    boxShadow: '0px 40px 40px #00000025',
    zIndex: 9999,
    position: 'relative',

    variants: {
        type: {
            freePlanLimit: {
                width: '560px',
                bg: '$neutral10',
            },
            teamPlanLimit: {
                width: '560px',
                bg: '$neutral10',
            },
            billingError: {
                width: '560px',
                bg: '$neutral10',
            },
        },
    },
})

const StyledModalActions = styled(Box, {
    width: '100%',
    height: '72px',
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingRight: '24px',
    paddingLeft: '24px',
    bt: '1px solid $neutral07',
    display: 'flex',
    gap: '16px',
    justifyContent: 'right',
})

const StyledPrimaryModalLabel = styled(Text, {
    fs: '20px',
    fw: 700,
})

const StyledSecondaryModalLabel = styled(Text, {
    fs: '14px',
    color: '$neutral05',
    lh: '1.4',
})

const StyledOverlay = styled(FlexColumn, {
    width: '100vw',
    height: '100vh',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: '$neutralOverlay',
    ai: 'center',
    justifyContent: 'center',
    position: 'fixed',
    zIndex: 9999,
})

const StyledLink = styled(Link, {
    boxSizing: 'border-box',
    fontWeight: '500',
    color: '$primary04',
    cursor: 'pointer',
    textDecoration: 'none',
})

const ModalLink = ({ children, url, onClick }) => {
    return (
        <StyledLink to={url} target="_blank" rel="noopener noreferrer" onClick={onClick}>
            {children}
        </StyledLink>
    )
}

/*
 * Dynamic global Modal component
 */
const GlobalModal = ({
    type = 'info',
    title,
    description,
    confirmationLabel,
    cancelButton,
    submitButton: submitButtonInitial,
}) => {
    const needsConfirmation = Boolean(confirmationLabel)
    const [confirmed, setConfirmed] = useState(!needsConfirmation)
    const dispatch = useDispatch()
    const { workspaceId } = useParams()
    const navigate = useNavigate()

    let modalTitle = title
    let modalDescription = description
    const submitButton = submitButtonInitial || {}
    const paymentsPageUrl = `/${workspaceId}/members`
    const plansDecriptionUrl = `https://www.range.io/`

    const _openEmail = e => {
        e.preventDefault()
        window.location.href = 'mailto:support@range.io'
        _cleanupModal()
    }

    const _onCancel = () => {
        cancelButton.onClick?.()
        _cleanupModal()
    }

    const _onSubmit = e => {
        submitButton?.onClick?.(e)
        _cleanupModal()
    }

    const _visitCustomerPortal = async organizationId => {
        if (!organizationId) return
        const { url } = await visitCustomerPortal(organizationId)
        window.location.href = url
    }

    const _cleanupModal = () => dispatch(ReduxActions.globalModalDataReset())

    const setupFreePlanModalFields = () => {
        modalTitle = (
            <FlexRow css={{ alignItems: 'center', gap: '6px' }}>
                <Icon name="warning" iconSize="24px" />
                Free Plan Limits Reached
            </FlexRow>
        )
        modalDescription = (
            <FlexRow
                css={{ backgroundColor: '$neutral09', br: 6, padding: '12px 14px', border: '1px solid $neutral07' }}
            >
                <Text css={{ color: '$neutral04', fw: 400 }}>
                    Your Organization has reached the limits of the Free plan on Range.{' '}
                    <ModalLink url={plansDecriptionUrl} onClick={_cleanupModal}>
                        Click here
                    </ModalLink>{' '}
                    to see what’s included in your Free plan, or upgrade to a paid Team plan.
                </Text>
            </FlexRow>
        )
        submitButton.onClick = () => navigate(paymentsPageUrl)
    }

    const setupTeamPlanModalFields = () => {
        modalTitle = (
            <FlexRow css={{ alignItems: 'center', gap: '6px' }}>
                <Icon name="warning" iconSize="24px" />
                Team Plan Limits Reached
            </FlexRow>
        )
        modalDescription = (
            <FlexRow
                css={{ backgroundColor: '$neutral09', br: 6, padding: '12px 14px', border: '1px solid $neutral07' }}
            >
                <Text css={{ color: '$neutral04', fw: 400 }}>
                    Your Organization has reached the limits of the Team plan on Range.{' '}
                    <ModalLink url={plansDecriptionUrl} onClick={_cleanupModal}>
                        Click here
                    </ModalLink>{' '}
                    to see what’s included in the Team plan, or contact us about upgrading to our Enterprise plan.
                </Text>
            </FlexRow>
        )
        submitButton.onClick = () => {}
    }

    const setupBillingErrorModalFields = () => {
        const selectedOrganization = useSelector(ReduxSelectors.selectedOrganization)
        const isEditingOrganizationAllowed = useSelector(ReduxSelectors.isUpdateAllowed('organization'))

        const { gracePeriodEndsTimestamp, priority } = selectedOrganization.billingError

        const timeLeft = formatDistance(gracePeriodEndsTimestamp, new Date())

        let errorText, titleText
        switch (priority) {
            case 1: {
                errorText = isEditingOrganizationAllowed
                    ? `Please update the payment details to maintain access to your team’s data.`
                    : 'Have a Range Admin update the payment details to maintain access to your team’s data.'

                errorText += ` (${timeLeft} remaining)`
                titleText = 'Account Billing Issue'
                if (isEditingOrganizationAllowed) {
                    submitButton.label = 'Update Payment Details'
                    submitButton.onClick = () => _visitCustomerPortal(selectedOrganization.id)
                }
                break
            }
            default:
                errorText = isEditingOrganizationAllowed ? (
                    <span>
                        Please email{' '}
                        <ModalLink url="#" onClick={_openEmail}>
                            support@range.io
                        </ModalLink>{' '}
                        to resolve.
                    </span>
                ) : (
                    <span>
                        Please have an Admin email{' '}
                        <ModalLink url="#" onClick={_openEmail}>
                            support@range.io
                        </ModalLink>{' '}
                        to resolve.
                    </span>
                )
                titleText = 'Billing Issue Identified'
                if (isEditingOrganizationAllowed) {
                    submitButton.label = 'Contact Us'
                    submitButton.onClick = _openEmail
                }
        }

        modalTitle = (
            <FlexRow css={{ alignItems: 'center', gap: '6px' }}>
                <Icon name="warning" iconSize="24px" />
                {titleText}
            </FlexRow>
        )
        modalDescription = (
            <FlexRow
                css={{ backgroundColor: '$neutral09', br: 6, padding: '12px 14px', border: '1px solid $neutral07' }}
            >
                <Text css={{ color: '$neutral04', fw: 400 }}>{errorText}</Text>
            </FlexRow>
        )
    }

    // Some global modal types have additional logic
    switch (type) {
        case 'freePlanLimit':
            setupFreePlanModalFields()
            break
        case 'teamPlanLimit':
            setupTeamPlanModalFields()
            break
        case 'billingError':
            setupBillingErrorModalFields()
            break
        default:
            break
    }

    return (
        <StyledOverlay>
            <StyledOuterBox type={type}>
                <Box css={{ padding: '24px' }}>
                    <StyledPrimaryModalLabel css={{ paddingBottom: '12px' }}>{modalTitle}</StyledPrimaryModalLabel>
                    <StyledSecondaryModalLabel>{modalDescription}</StyledSecondaryModalLabel>
                    {needsConfirmation && (
                        <FlexRow css={{ gap: '12px', alignItems: 'center', paddingTop: '24px' }}>
                            <Checkbox
                                id="checkbox-confirm"
                                variant="destructive"
                                size="lg"
                                defaultChecked={false}
                                checked={confirmed}
                                onCheckedChange={setConfirmed}
                            />
                            <StyledSecondaryModalLabel>
                                <label htmlFor="checkbox-confirm">{confirmationLabel}</label>
                            </StyledSecondaryModalLabel>
                        </FlexRow>
                    )}
                </Box>
                <StyledModalActions>
                    <Button
                        css={{ height: '40px' }}
                        variant={submitButton ? 'secondary' : 'primary'}
                        size="lg"
                        onClick={_onCancel}
                    >
                        {cancelButton?.label || 'Cancel'}
                    </Button>
                    {submitButton.label && (
                        <Button
                            variant={type === 'destructive' ? 'destructive' : 'primary'}
                            size="lg"
                            onClick={_onSubmit}
                            disabled={!confirmed}
                        >
                            {submitButton.label}
                        </Button>
                    )}
                </StyledModalActions>
            </StyledOuterBox>
        </StyledOverlay>
    )
}

GlobalModal.displayName = 'Global Modal'

const isPredefinedPlan = type => ['freePlanLimit', 'teamPlanLimit', 'billingError'].some(planType => planType === type)

GlobalModal.propTypes = {
    type: PropTypes.oneOf(['info', 'destructive', 'freePlanLimit', 'teamPlanLimit', 'billingError']),
    title: (props, propName, componentName) => {
        if (!isPredefinedPlan(props.type) && typeof props[propName] !== 'string') {
            return new Error('Please provide a title prop!')
        }
    },
    description: (props, propName, componentName) => {
        if (!isPredefinedPlan(props.type) && typeof props[propName] !== 'string') {
            return new Error('Please provide a description prop!')
        }
    },
    confirmationLabel: PropTypes.string,
    cancelButton: PropTypes.exact({
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func,
    }),
    submitButton: PropTypes.exact({
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func,
    }),
}

export default GlobalModal
