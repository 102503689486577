import PropTypes from 'prop-types'
import React from 'react'
import * as Popover from '@radix-ui/react-popover'
import { RangeUITheme, styled } from '../range-theme/index.js'
import { Box, Checkbox, DatePicker, FlexRow, Icon, SingleSelectUser, Text, Tooltip } from '../components-reusable'
import { useOnClickOutside } from '../components-reusable/hooks/index.js'
import Identifier from './Identifier.js'
import ListViewRowAssignee from './ListViewRowAssignee'
import {
    AdditionalInformation,
    CanvasSheetPill,
    CreatedOnLabel,
    DueDatePill,
    LastUpdatedLabel,
} from './ListViewTaskRowElements'
import StatusDot from './StatusDot.js'
import TagsPill from './TagsPill'
import { StatusItem } from './StatusSelector.js'

const StyledListRow = styled(FlexRow, {
    alignItems: 'center',
    minHeight: '48px',
    background: '$neutral10',
    paddingLeft: '16px',
    paddingRight: '16px',
    gap: '12px',
    borderBottom: '1px solid $neutral07',
    zIndex: 2,
    transitionDuration: '0.4s',
    '&:hover': {
        background: '$primary02',
        cursor: 'pointer',
    },
})

const StyledCollaborationNameContainer = styled(FlexRow, {
    alignItems: 'center',
    justifyContent: 'start',
    width: 0,
    flexGrow: 1,
    flexShrink: 1,
    marginLeft: 12,
    minWidth: 350,
})

const StyledCollaborationName = styled(Text, {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '21px',
    color: '$neutral04',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
})

const StyledCheckbox = styled(Checkbox, {
    marginRight: '12px',
})

const SecondaryText = styled(Text, {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '21px',
    color: '$neutral05',
})

const StyledPopoverContent = styled(Popover.Content, {
    color: '$neutral04',
    backgroundColor: '$neutral09',
    outline: 'none',
    padding: '8px',
    borderRadius: '6px',
    border: '1px solid $neutral07',
    boxShadow: `${RangeUITheme.shadows.lg}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: '8px',
    zIndex: 2,
    height: 'auto',
    maxHeight: `var(--radix-popper-available-height)`,
})

const StyledStatusTrigger = styled('div', {
    display: 'inline-flex',
})

const RowCell = React.forwardRef(({ cellWidth, children, css }, ref) => (
    <FlexRow ref={ref} css={{ ...css, width: cellWidth, minWidth: cellWidth, maxWidth: cellWidth }}>
        {children}
    </FlexRow>
))

const FallbackCell = ({ iconName, text, onClick, dataCy }) => {
    return (
        <FlexRow
            data-cy={dataCy}
            onClick={e => {
                if (onClick) {
                    e.stopPropagation()
                    onClick(e)
                }
            }}
            css={{ alignItems: 'center' }}
        >
            {iconName && <Icon name={iconName} iconSize="12px" css={{ marginRight: '8px' }} shape="circle" />}
            <SecondaryText>{text}</SecondaryText>
        </FlexRow>
    )
}

const StatusDotCell = ({ isArchived, isTask, selectedStatus, allStatuses, onStatusChange }) => {
    const { isCompleted, label, color } = selectedStatus

    return (
        <Popover.Root modal={false}>
            <Tooltip tooltipText="Update Status" side="bottom">
                <Popover.Trigger asChild>
                    <StyledStatusTrigger
                        onClick={e => {
                            e.stopPropagation() // do not trigger whole row click
                        }}
                    >
                        <StatusDot
                            isArchived={isArchived}
                            statusIsCompleted={isCompleted}
                            isTask={isTask}
                            statusColor={color}
                            statusLabel={label}
                        />
                    </StyledStatusTrigger>
                </Popover.Trigger>
            </Tooltip>
            <Popover.Portal>
                <StyledPopoverContent side="bottom" align="start" sideOffset={4} collisionPadding={4}>
                    {Object.values(allStatuses).map(status => (
                        <div
                            key={status.id}
                            onClick={e => {
                                e.stopPropagation() // do not trigger whole row click
                                onStatusChange(status.id)
                            }}
                        >
                            <StatusItem selectedStatus={selectedStatus} status={status} />
                        </div>
                    ))}
                </StyledPopoverContent>
            </Popover.Portal>
        </Popover.Root>
    )
}

// ListTaskRow component
const ListViewTaskRow = ({
    assignee,
    assigneeIsOnline,
    canvasId,
    createdAt,
    dueDate,
    geometry,
    id,
    identifier,
    isArchived,
    mostRecentUpdateDate,
    name,
    onGoToCanvasId,
    onItemSelect,
    sheetLabel,
    showFullContent,
    status,
    allStatuses,
    tagNames,
    isChecked,
    handleItemChecked,
    onDateChange,
    onAssigneeChange,
    onStatusChange,
    allAvailableAssigneesShapes,
    projectIdentifier,
    taskRowCheckboxDataId,
}) => {
    const [showDatePicker, setShowDatePicker] = React.useState()
    const [showAssigneeDropdown, setShowAssigneeDropdown] = React.useState()

    const dueDateRow = React.useRef()
    const assigneeCell = React.useRef()

    useOnClickOutside(dueDateRow, () => setShowDatePicker(false))
    useOnClickOutside(assigneeCell, () => setShowAssigneeDropdown(false))

    const handleStatusChange = statusId => {
        onStatusChange(id, statusId)
    }

    const singleSelectUserProps = {
        projectParticipantShapes: allAvailableAssigneesShapes,
        selectedUser: assignee,
        setSelectedUserId: userId => {
            onAssigneeChange({ userId, collaborationId: id })
            setShowAssigneeDropdown(false)
        },
    }

    const renderIdentifierCell = () =>
        identifier && (
            <Identifier
                projectIdentifier={projectIdentifier}
                collaborationIdentifier={identifier}
                variant="medium"
                noPrefix
                css={{ fontWeight: '400' }}
            />
        )

    const renderAssigneeCell = () => {
        if (showAssigneeDropdown)
            return (
                <SingleSelectUser
                    withoutIcon
                    withNoAssigneeOption
                    css={{ height: '40px' }}
                    {...singleSelectUserProps}
                />
            )
        if (assignee)
            return (
                <ListViewRowAssignee
                    participantShape={assignee}
                    isOnline={assigneeIsOnline}
                    onClick={e => {
                        e.stopPropagation()
                        setShowAssigneeDropdown(val => !val)
                    }}
                />
            )

        return (
            <FallbackCell
                dataCy="list-view-assignee"
                iconName="user"
                text="Add Assignee"
                onClick={() => setShowAssigneeDropdown(val => !val)}
            />
        )
    }

    const isNameEmpty = name.length === 0
    return (
        <StyledListRow data-cy="list-view-task-row" className="list-view__task-row" onClick={() => onItemSelect(id)}>
            <FlexRow css={{ flex: '1', alignItems: 'center' }}>
                <StyledCheckbox
                    size="md"
                    defaultChecked={true}
                    checked={isChecked}
                    onClick={e => e.stopPropagation()} // do not bubble, so the row is not selected
                    onCheckedChange={() => handleItemChecked(id)}
                    data-id={taskRowCheckboxDataId}
                />
                <StatusDotCell
                    isArchived={isArchived}
                    selectedStatus={status}
                    allStatuses={allStatuses}
                    isTask={true}
                    onStatusChange={handleStatusChange}
                />
                <StyledCollaborationNameContainer>
                    <StyledCollaborationName empty={isNameEmpty.toString()} data-cy="list-view-task-name">
                        {isNameEmpty ? 'Untitled task' : name}
                    </StyledCollaborationName>
                    <LastUpdatedLabel mostRecentUpdateDate={mostRecentUpdateDate} />
                </StyledCollaborationNameContainer>
            </FlexRow>
            <FlexRow css={{ gap: '12px', alignItems: 'center' }}>
                <RowCell cellWidth="100px">{renderIdentifierCell()}</RowCell>
                <RowCell cellWidth="180px" ref={assigneeCell}>
                    {renderAssigneeCell()}
                </RowCell>
                <RowCell cellWidth="140px" ref={dueDateRow}>
                    {dueDate ? (
                        <DueDatePill
                            dueDate={dueDate}
                            isCompleted={status.isCompleted}
                            isArchived={isArchived}
                            onClick={e => {
                                e.stopPropagation()
                                setShowDatePicker(val => !val)
                            }}
                        />
                    ) : (
                        <FallbackCell
                            dataCy="list-view-due-date"
                            iconName="calendar"
                            text="Add Due Date"
                            onClick={() => setShowDatePicker(val => !val)}
                        />
                    )}
                    {showDatePicker && (
                        <Box css={{ position: 'absolute', mt: '28px', zIndex: 200 }}>
                            <DatePicker
                                onChange={dueDate => {
                                    onDateChange({ itemId: id, dueDate })
                                    setShowDatePicker(false)
                                }}
                                value={dueDate}
                            />
                        </Box>
                    )}
                </RowCell>
                <RowCell cellWidth="250px">
                    <CanvasSheetPill sheetLabel={sheetLabel} onClick={() => onGoToCanvasId(id)} />
                </RowCell>
                {showFullContent && (
                    <>
                        <RowCell cellWidth="60px">
                            <TagsPill.Simple tagNames={tagNames} />
                        </RowCell>
                        <RowCell cellWidth="30px">
                            <AdditionalInformation geometry={geometry()} canvasId={canvasId} />
                        </RowCell>
                        <RowCell cellWidth="100px">
                            <CreatedOnLabel createdOn={createdAt} />
                        </RowCell>
                    </>
                )}
            </FlexRow>
        </StyledListRow>
    )
}

ListViewTaskRow.propTypes = {
    assignee: PropTypes.object,
    assigneeIsOnline: PropTypes.bool,
    createdAt: PropTypes.object, // Date
    dueDate: PropTypes.object, // Date
    mostRecentUpdate: PropTypes.object, // Update object
    name: PropTypes.string,
    sheetLabel: PropTypes.string,
    showFullContent: PropTypes.bool,
    statusLabel: PropTypes.string,
    statusColor: PropTypes.string,
    tagNames: PropTypes.arrayOf(PropTypes.string),
    projectIdentifier: PropTypes.string,
    identifier: PropTypes.string,
}

export default ListViewTaskRow
